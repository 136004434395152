import { Contract, Qualification, Region } from 'src/types'

export interface QualificationMatrix {
  // The matrix may be related to a contract to the region.
  // Otherwise it is related to the current region
  contractName: string | undefined
  rows: Qualification[]
}

export const getQualifications = (
  matrices: QualificationMatrix[],
): Qualification[] => {
  return matrices.flatMap((matrix) => matrix.rows)
}

export const getQualificationIds = (qualifications: Qualification[]) =>
  qualifications.reduce(
    (
      acc,
      {
        providerAffiliatePartnerId,
        providerDeviceAssociationId,
        providerPersonalId,
      },
    ) => {
      providerPersonalId && acc.push(providerPersonalId)
      providerAffiliatePartnerId && acc.push(providerAffiliatePartnerId)
      providerDeviceAssociationId && acc.push(providerDeviceAssociationId)
      return acc
    },
    [] as number[],
  )

/**
 * Specifies which columns are to be displayed in a qualification matrix
 * */
interface QualificationMatrixDisplaySettings {
  providerAffiliatePartnerId: boolean
  providerDeviceAssociationId: boolean
  providerPersonalId: boolean
}

/**
 * Determine which columns should be displayed in the qualification matrix.
 * A column is displayed if at least one qualification defines an ID for this column.
 * */
export const getMatrixDisplayColumnSettings = (
  qualifications: Qualification[],
): QualificationMatrixDisplaySettings => {
  const initialSetting: QualificationMatrixDisplaySettings = {
    providerAffiliatePartnerId: false,
    providerDeviceAssociationId: false,
    providerPersonalId: false,
  }
  return qualifications.reduce((settings, qualification) => {
    settings.providerAffiliatePartnerId =
      settings.providerAffiliatePartnerId ||
      !!qualification.providerAffiliatePartnerId
    settings.providerDeviceAssociationId =
      settings.providerDeviceAssociationId ||
      !!qualification.providerDeviceAssociationId
    settings.providerPersonalId =
      settings.providerPersonalId || !!qualification.providerPersonalId
    return settings
  }, initialSetting)
}

export const buildQualificationMatrices = (
  region: Region,
  selectedContracts: Contract[],
): QualificationMatrix[] => {
  const qualificationLabels = new Set()
  const matrices: QualificationMatrix[] = []
  for (const contract of selectedContracts) {
    const filteredQualifications: Qualification[] = []
    for (const qualification of contract.qualifications) {
      if (!qualificationLabels.has(qualification.label)) {
        filteredQualifications.push(qualification)
        qualificationLabels.add(qualification.label)
      }
    }
    if (filteredQualifications.length > 0) {
      matrices.push({
        contractName: contract.abbreviation,
        rows: filteredQualifications,
      })
    }
  }
  const filteredRegionQualifications = region.qualifications.filter(
    (qualification) => !qualificationLabels.has(qualification.label),
  )
  if (filteredRegionQualifications.length > 0) {
    matrices.push({
      contractName: undefined,
      rows: filteredRegionQualifications,
    })
  }
  return matrices
}

export const getQualificationIdsForQualification = (
  qualification: Qualification,
): number[] => {
  const result: number[] = []
  if (qualification.providerPersonalId) {
    result.push(qualification.providerPersonalId)
  }
  if (qualification.providerAffiliatePartnerId) {
    result.push(qualification.providerAffiliatePartnerId)
  }
  if (qualification.providerDeviceAssociationId) {
    result.push(qualification.providerDeviceAssociationId)
  }
  return result
}

export const isQualificationSelected = (
  qualification: Qualification,
  selectedQualificationIds: string[],
): boolean => {
  const idsList = getQualificationIdsForQualification(qualification)
  for (const id of idsList) {
    if (selectedQualificationIds.includes(id.toString())) {
      return true
    }
  }
  return false
}
