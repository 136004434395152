import type { AxiosResponse } from 'axios'
import type { FormikValues } from 'formik'

import type { Contract, Region, Release, SupportedSoftware } from 'src/types'

import axi from './axios'

interface RequestProps {}

interface ApplicationFormResponse {
  applicationId: number
}

interface GetContractsProps extends RequestProps {}
type GetContracts = {
  (requestProps?: GetContractsProps): Promise<AxiosResponse<Contract[]>>
}
/**
 * @description Returns all available contracts grouped in regions.
 */
export const getContracts: GetContracts = (requestProps) => {
  const requestUrl = `contracts/`

  return axi.get(requestUrl, requestProps)
}

interface GetRegionsProps extends RequestProps {}
type GetRegions = {
  (requestProps?: GetRegionsProps): Promise<AxiosResponse<Region[]>>
}
/**
 * @description Returns all available regions.
 */
export const getRegions: GetRegions = (requestProps) => {
  const requestUrl = `regions/`

  return axi.get(requestUrl, requestProps)
}

interface GetReleaseProps extends RequestProps {}
type GetRelease = {
  (requestProps?: GetReleaseProps): Promise<AxiosResponse<Release>>
}

/**
 * @description Returns the current release.
 */
export const getRelease: GetRelease = (requestProps) => {
  const requestUrl = `release/`

  return axi.get(requestUrl, requestProps)
}

/**
 * @description Returns supported software. Wrong suffix "s", because it is a list.
 */
export const getSupportedSoftwares: GetSupportedSoftwares = (requestProps) => {
  const requestUrl = 'supported-softwares/'

  return axi.get(requestUrl, requestProps)
}

interface GetSupportedSoftwaresProps extends RequestProps {}
type GetSupportedSoftwares = {
  (requestProps?: GetSupportedSoftwaresProps): Promise<
    AxiosResponse<SupportedSoftware[]>
  >
}

/**
 * @description Posts an application contract participation form.
 */
interface PostContractParticipationFormProps extends RequestProps {
  regionalContractIds: number[]
  releaseId: number
  values: FormikValues
}
type PostContractParticipationForm = {
  (requestProps: PostContractParticipationFormProps): Promise<
    AxiosResponse<ApplicationFormResponse>
  >
}
export const postContractParticipationForm: PostContractParticipationForm = ({
  regionalContractIds,
  releaseId,
  values,
}) => {
  const requestUrl = `applications/contract_participation_form/`
  const formData = new FormData()

  Object.entries(values).forEach(([key, value]) => {
    // we need to dump the array(s) to json as otherwise it will passed as string
    if (key === 'verahCoworkers' || key === 'additionalAttributeIds') {
      formData.append(key, JSON.stringify(value))
    } else {
      formData.append(key, value)
    }
  })
  formData.append('releaseId', `${releaseId}`)
  formData.append('regionalContractIds', regionalContractIds.join(','))

  return axi.post(requestUrl, formData)
}

interface PostMembershipParticipationFormProps extends RequestProps {
  releaseId: number
  values: FormikValues
}
type PostMembershipParticipationForm = {
  (requestProps: PostMembershipParticipationFormProps): Promise<
    AxiosResponse<ApplicationFormResponse>
  >
}
/**
 * @description Posts an application membership participation form.
 */
export const postMembershipParticipationForm: PostMembershipParticipationForm =
  ({ releaseId, values }) => {
    const requestUrl = `memberships/membership_participation_form/`
    const formData = new FormData()

    Object.entries(values).forEach(([key, value]) =>
      formData.append(key, value),
    )
    formData.append('releaseId', `${releaseId}`)

    return axi.post(requestUrl, formData)
  }

interface PostContactRequestProps extends RequestProps {
  releaseId: number
  regionId: number | null
  concern: 1 | 2 | 3
  title: string
  firstName: string
  lastName: string
  email: string
  message: string
}
type PostContactRequest = {
  (requestProps: PostContactRequestProps): Promise<AxiosResponse>
}
/**
 * @description POST a contact request.
 */
export const postContactRequest: PostContactRequest = (requestProps) => {
  const requestUrl = 'contact_requests/contact_request_form/'
  const formData = new FormData()

  Object.entries(requestProps).forEach(([key, value]) =>
    formData.append(key, value),
  )

  return axi.post(requestUrl, formData)
}

/**
 * Download report file
 * @param context 'applications' | 'memberships'
 * @param id string
 */
export const downloadReportFile = (
  context: 'applications' | 'memberships',
  id: string,
) => {
  const requestUrl = `${context}/${id}/pdf_report/`

  return axi.get(requestUrl, { responseType: 'blob' })
}

/**
 * Download pre sepa mandate file
 * @param applicant object
 * @param regionSlug string
 */
export const downloadPreSepaMandateFile = (
  applicant: any,
  regionSlug: string,
) => {
  const requestUrl = 'memberships/pre_sepa_mandate/'
  const sepaData = {
    applicant: applicant,
    regionSlug: regionSlug,
  }
  return axi.post(requestUrl, JSON.stringify(sepaData), {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'blob',
  })
}

export interface AdditionalPrivacyPolicy {
  additionalDataPrivacyInfo: string
}
interface GetAdditionalPrivacyPolicyProps extends RequestProps {}
type GetAdditionalPrivacyPolicy = {
  (requestProps?: GetAdditionalPrivacyPolicyProps): Promise<
    AxiosResponse<AdditionalPrivacyPolicy>
  >
}
export const getAdditionalPrivacyPolicy: GetAdditionalPrivacyPolicy = (
  requestProps,
) => {
  const requestUrl = `data-privacy-settings/details/`

  return axi.get(requestUrl, requestProps)
}
