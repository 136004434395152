import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

export const useTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
    '&:before': {
      border: `1px solid ${theme.palette.grey['300']}`,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey['300']}`,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
  },
}))

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      listStyleType: 'none',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    buttonLabel: {
      padding: 0,
      '&:disabled': {
        color: theme.palette.primary.light,
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold as number,
    },
    inActive: {
      opacity: '0.3',
    },
    active: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    arrow: {
      width: 200,
      height: 50,
      margin: '0 6px',
      display: 'inline-block',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        width: 50,
        transform: 'rotate(90deg)',
      },

      '&::before': {
        content: '""',
        display: 'block',
        width: 6,
        height: 6,
        top: '40%',
        right: 0,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.light,
        borderWidth: '2px 2px 0 0',
        position: 'absolute',
        transform: 'translate(0px, 2px) rotate(45deg)',
      },

      '&::after': {
        content: '""',
        display: 'block',
        top: '50%',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.light,
        position: 'absolute',
        width: '100%',
        height: 0,
        borderWidth: '2px 0 0 0',
      },
    },
  }),

  { name: 'Stepper' },
)

export default useStyles
