import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  () => ({
    error: {
      minHeight: '100%',
      alignItems: 'center',
      paddingTop: 80,
      paddingBottom: 80,
    },
    image: {
      maxWidth: '100%',
      width: 560,
      maxHeight: 300,
      height: 'auto',
    },
  }),

  { name: 'ErrorView' },
)

export default useStyles
