import type { FC, ReactNode } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import Button from 'src/components/Button/Button'
import type { ContentStripeProps } from 'src/components/ContentStripe/ContentStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'

import useStyles from './ContactStripe.styles'

export interface ContactStripeProps
  extends Omit<ContentStripeProps, 'children'> {
  children?: ReactNode
  regionPhone?: string | null | undefined
  serviceHoursRow1?: string | null | undefined
  serviceHoursRow2?: string | null | undefined
}

export const ContactStripe: FC<ContactStripeProps> = ({
  className,
  children,
  regionPhone,
  serviceHoursRow1,
  serviceHoursRow2,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <ContentStripe
      className={clsx(classes.root, className)}
      data-test-id={TestIds.components.contactStripe}
      backgroundColor="fog"
      {...props}
    >
      <Grid container spacing={3}>
        <Grid className={clsx(classes.column, classes.columnLeft)} item sm>
          <Typography color="primary" gutterBottom variant="h3" component="h5">
            {t('ContactStripe.questions')}
          </Typography>

          <Typography variant="h3" component="span">
            {t('ContactStripe.contactUs')}
          </Typography>
        </Grid>

        <Grid className={clsx(classes.column)} item sm>
          <Typography color="primary" gutterBottom variant="h3" component="h5">
            {t('ContactStripe.phoneNumber')}
          </Typography>

          <Typography variant="h3" component="span">
            {regionPhone ? regionPhone : t('Views.Contact.hzvContactPhone')}
          </Typography>
        </Grid>

        <Grid className={clsx(classes.column, classes.columnRight)} item sm>
          <Box mb={1}>
            <Button
              color="secondary"
              component={RouterLink}
              to={routes.root.routes!.contact.path}
              variant="contained"
            >
              {t('ContactStripe.moreContact')}
            </Button>
          </Box>

          <Typography color="primary" variant="subtitle2" component="span">
            {serviceHoursRow1
              ? serviceHoursRow1
              : t('ContactStripe.openingHours01')}
            <br />
            {serviceHoursRow1
              ? serviceHoursRow2
              : t('ContactStripe.openingHours02')}
          </Typography>
        </Grid>
      </Grid>

      {children}
    </ContentStripe>
  )
}

export default ContactStripe
