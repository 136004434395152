import { useContext } from 'react'

import type { ContractsContextValue } from 'src/contexts/ContractsContext'
import ContractsContext from 'src/contexts/ContractsContext'

/**
 * @description Provides the ContractsContext.
 */
const useContracts = (): ContractsContextValue => useContext(ContractsContext)

export default useContracts
