import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles<Theme, { aspectRatio: string }>(
  (theme: Theme) => ({
    img: {
      display: 'block',
      height: '100%',
      left: '0',
      objectFit: 'contain',
      objectPosition: 'center center',
      position: 'absolute',
      top: '0',
      width: '100%',
    },
    root: {
      paddingTop: (props) => props.aspectRatio,
      position: 'relative',
      width: '100%',
    },
  }),

  { name: 'AspectRatinImg' },
)

export default useStyles
