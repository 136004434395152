import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import { TestIds } from 'src/testIds'
import {
  AdditionalPrivacyPolicy,
  getAdditionalPrivacyPolicy,
} from 'src/services/api/api'
import CertificateStripe from 'src/components/CertificateStripe/CertificateStripe'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import { Markdown } from 'src/components/Markdown/Markdown'
import Page from 'src/components/Page/Page'

const PrivacyTestIds = TestIds.views.privacy

const PrivacyView: FC = () => {
  const { t } = useTranslation()
  const [additionalPrivacyPolicy, setAdditionalPrivacyPolicy] =
    useState<AdditionalPrivacyPolicy | null>(null)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: _additionalPrivacyPolicy } =
          await getAdditionalPrivacyPolicy()

        setAdditionalPrivacyPolicy(_additionalPrivacyPolicy)
      } catch (error) {
        setAdditionalPrivacyPolicy(null)
      }
    }

    fetchData()
  }, [t])
  return (
    <Page
      data-test-id={PrivacyTestIds.pageWrapper}
      title={t('Views.Privacy.title')}
    >
      <ContentStripe backgroundColor="secondary">
        <Grid container>
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h1"
              dangerouslySetInnerHTML={{ __html: t('Views.Privacy.header') }}
            />
          </Grid>
        </Grid>
      </ContentStripe>

      <ContentStripe backgroundColor="fog">
        <Box sx={{ width: '100%' }}>
          <Card>
            <CardContent>
              {!additionalPrivacyPolicy ? (
                <Skeleton variant="text" width="100%" />
              ) : (
                <Markdown>
                  {additionalPrivacyPolicy.additionalDataPrivacyInfo}
                </Markdown>
              )}
            </CardContent>
          </Card>
        </Box>
      </ContentStripe>

      <CertificateStripe />
      <ContactStripe />
    </Page>
  )
}

export default PrivacyView
