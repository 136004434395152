import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import type { DialogProps } from '@material-ui/core'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'

import { Contract } from 'src/types'
import { openInNewTab, withHttps } from 'src/utils'

import useStyles from './ContractCardDialog.styles'

export interface ContractCardDialogProps extends DialogProps {
  contract: Contract
  contractLogo: JSX.Element
  openModalWith: 'details' | 'insurance' | 'software' | undefined
  regionName: string | undefined
}

export const ContractCardDialog: FC<ContractCardDialogProps> = ({
  contract,
  contractLogo,
  openModalWith,
  onClose,
  regionName,
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })

  return (
    <Dialog
      classes={{ paper: clsx(classes.dialogRoot) }}
      fullScreen={!smUp}
      maxWidth="md"
      onClose={onClose}
      scroll="paper"
      {...props}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          {onClose && (
            <Grid item xs={12}>
              <IconButton
                onClick={(event) => onClose(event, 'backdropClick')}
                className={clsx(classes.modalCloseButton)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={12} sm={8}>
            <Typography color="primary" variant="h2">
              {contract.abbreviation} in {regionName}
            </Typography>
            <Typography>{contract.detailText}</Typography>
          </Grid>
          {smUp && (
            <Grid item sm={3}>
              {contractLogo}
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: clsx(classes.muiDialogContentRoot) }}>
        <Grid container spacing={3}>
          {openModalWith === 'details' && (
            <>
              <Grid item xs={12}>
                <Typography gutterBottom color="primary" variant="h3">
                  {t('ContractCardDialog.detailsModal.subHeader')}
                </Typography>
                <List disablePadding>
                  {contract.highlights.map((highlight, index) => {
                    return (
                      <ListItem key={index} disableGutters dense>
                        <ListItemIcon>
                          <CheckIcon className={clsx(classes.checkIcon)} />
                        </ListItemIcon>
                        <ListItemText primary={highlight} />
                      </ListItem>
                    )
                  })}
                </List>
              </Grid>
              <Grid item xs={12}>
                {contract.link1 && (
                  <Link
                    className={clsx(classes.iconAndTextLink)}
                    color="primary"
                    onClick={() => openInNewTab(withHttps(contract.link1))}
                    underline="none"
                    variant="subtitle2"
                  >
                    <ChevronRightIcon fontSize="small" color="primary" />
                    {contract.linkText1}
                  </Link>
                )}
                {contract.link2 && (
                  <Link
                    className={clsx(classes.iconAndTextLink)}
                    color="primary"
                    onClick={() => openInNewTab(withHttps(contract.link2))}
                    underline="none"
                    variant="subtitle2"
                  >
                    <ChevronRightIcon fontSize="small" color="primary" />
                    {contract.linkText2}
                  </Link>
                )}
              </Grid>
            </>
          )}
          {openModalWith === 'insurance' && (
            <>
              <Grid item xs={12}>
                <Typography gutterBottom color="primary" variant="h3">
                  {t('ContractCardDialog.insuranceModal.subHeader')}
                </Typography>
                <List disablePadding>
                  {contract.participatingInsuranceCompanies.map(
                    (InsuranceCompanies, index) => {
                      return (
                        <ListItem
                          classes={{ root: clsx(classes.muiListItemRoot) }}
                          dense
                          disableGutters
                          key={index}
                        >
                          <ListItemText primary={InsuranceCompanies} />
                        </ListItem>
                      )
                    },
                  )}
                </List>
              </Grid>
              <Grid item xs={12}>
                {contract.link1 && (
                  <Link
                    className={clsx(classes.iconAndTextLink)}
                    color="primary"
                    onClick={() => openInNewTab(withHttps(contract.link1))}
                    underline="none"
                    variant="subtitle2"
                  >
                    <ChevronRightIcon fontSize="small" color="primary" />
                    {contract.linkText1}
                  </Link>
                )}
                {contract.link2 && (
                  <Link
                    className={clsx(classes.iconAndTextLink)}
                    color="primary"
                    onClick={() => openInNewTab(withHttps(contract.link2))}
                    underline="none"
                    variant="subtitle2"
                  >
                    <ChevronRightIcon fontSize="small" color="primary" />
                    {contract.linkText2}
                  </Link>
                )}
              </Grid>
            </>
          )}
          {openModalWith === 'software' && (
            <Grid item>
              <Typography gutterBottom color="primary" variant="h3">
                {t('ContractCardDialog.softwareModal.subHeader')}
              </Typography>
              <List disablePadding>
                {contract.supportedSoftwares.map((software) => {
                  return (
                    <ListItem
                      classes={{ root: clsx(classes.muiListItemRoot) }}
                      dense
                      disableGutters
                      key={software.id}
                    >
                      <ListItemText
                        primary={`${software.name} (${software.manufacturer})`}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ContractCardDialog
