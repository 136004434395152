import type { FC, ReactNode } from 'react'
import React, { useEffect } from 'react'
import { generatePath, Redirect, useParams } from 'react-router-dom'

import routes from 'src/routes'
import useContracts from 'src/hooks/useContracts'
import useRegions from 'src/hooks/useRegions'
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen'

interface RegionGuardProps {
  children?: ReactNode
}

/**
 * Shows views only if a regionSlug was provided and if the regionSlug
 * actually exists within the fetched regions.
 */
const RegionGuard: FC<RegionGuardProps> = ({ children }) => {
  const { state: regionState } = useRegions()
  const { actions: contractActions } = useContracts()
  const params = useParams<{ regionSlug: string }>()
  const isFetching = regionState.loading
  const isRegion = regionState.regions.some(
    (region) => region.slug.toLowerCase() === params.regionSlug?.toLowerCase(),
  )

  // Set current region after fetching regions and validating the region exists.
  useEffect(() => {
    if (!isFetching && isRegion) {
      contractActions.setCurrentRegion(params.regionSlug || '')
    }
  }, [isFetching, isRegion]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isFetching) {
    return <LoadingScreen />
  }

  if (!isRegion && regionState.regions.length) {
    return (
      <Redirect
        to={generatePath(routes.errorPage.path, {
          errorCode: 404,
        })}
      />
    )
  }

  return <>{children}</>
}

export default RegionGuard
