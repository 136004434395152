import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const tags = ['h1', 'h2', 'h3', 'h4'] as const
  const nestedRules: Record<string, any> = {}

  tags.forEach((tag) => {
    nestedRules[`& ${tag}`] = {
      color: theme.palette.primary.main,
      marginTop: '0.35em',
      ...theme.typography[tag],
    }
  })
  nestedRules['& ul'] = {
    marginLeft: theme.spacing(1),
  }
  nestedRules['& ol'] = {
    fontSize: 'larger',
    marginLeft: theme.spacing(1),
  }
  nestedRules['& li'] = {
    marginLeft: theme.spacing(3),
  }
  return {
    root: nestedRules,
  }
})

export default useStyles
