import type { FC, ReactNode } from 'react'
import React, { useEffect } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'

import routes from 'src/routes'
import { getPreviewParams } from 'src/utils'
import useRelease from 'src/hooks/useRelease'

interface PreviewGuardProps {
  children?: ReactNode
}

const PreviewGuard: FC<PreviewGuardProps> = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const { state: releaseState } = useRelease()

  /**
   * On every change of location (and previewParams), replace the search string of the current history entry
   * so that it matches the current preview parameters.
   */
  useEffect(() => {
    const urlParams = new URLSearchParams()
    const previewParams = getPreviewParams(location)
    const paramsChanged =
      releaseState.previewParams?.apiKey !== previewParams?.apiKey &&
      releaseState.previewParams?.releaseId !== previewParams?.releaseId

    if (releaseState.previewParams) {
      urlParams.set('api_key', releaseState.previewParams.apiKey)
      urlParams.set('release_id', releaseState.previewParams.releaseId)

      // Only set history, if the parameters changed, otherwise we will go into an endless loop
      // because the useEffect-dependency on `location` will always trigger again
      if (releaseState.isValidRelease && paramsChanged) {
        history.replace({
          search: urlParams.toString(),
        })
      }

      // Redirect to the 404 page, if we don't have a valid release
      if (!releaseState.isValidRelease) {
        // Unfortunately history.push doesn't accept the `search` parameter like history.replace
        // So we have to build the path ourselves...
        // ¯\_(ツ)_/¯
        const errorPage = `${generatePath(routes.errorPage.path, {
          errorCode: 404,
        })}?${urlParams.toString()}`
        history.push(errorPage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Always trigger when location changes
    location,
    // Also trigger, when .isValidRelease changes, because we can only
    // determine if a release is valid by making a request.
    // We initially assume a valid release, thus for the redirect above to work
    // we need to listen for .isValidRelease changes.
    releaseState.isValidRelease,
  ])

  return <>{children}</>
}

export default PreviewGuard
