import type { FC, ReactNode } from 'react'
import React, { createContext, useEffect, useState } from 'react'

import type { Contract } from 'src/types'
import useUserData from 'src/hooks/useUserDate'
import api from 'src/services/api'

export interface ContractsProviderProps {
  children?: ReactNode
}

export interface GetContractsProps {
  by: keyof Contract
  value: string
}

const initialContracts: Contract[] = []

export interface ContractsContextValue {
  actions: {
    fetchContracts: () => Promise<void>
    setCurrentRegion: (regionSlug: string) => void
    setSelectedContract: (contractId: number) => void
    setSelectedContracts: (contractIds: number[]) => void
    setSelectedFilters: (selectedFilters: string[]) => void
  }

  getters: {
    getContracts: (props: GetContractsProps) => Contract[]
    getFilteredContracts: () => Contract[]
    getSelectedContractsAbbreviations: () => string[]
    getSubSetOfSelectedAndFilteredContracts: () => Contract[]
  }

  state: {
    contractList: {
      currentRegion?: string
      selectedContracts: number[]
      selectedFilters: string[]
      canApplyForFilteredContracts: Contract[]
      alreadyAppliedForFilteredContracts: Contract[]
    }
    contracts: Contract[]
    fetchContractsRequest: {
      loading: boolean
    }
    loading: boolean
  }
}

const ContractsContext = createContext<ContractsContextValue>({
  actions: {
    fetchContracts: () => new Promise(() => {}),
    setCurrentRegion: () => {},
    setSelectedContract: () => {},
    setSelectedContracts: () => {},
    setSelectedFilters: () => {},
  },

  getters: {
    getContracts: () => [],
    getFilteredContracts: () => [],
    getSelectedContractsAbbreviations: () => [],
    getSubSetOfSelectedAndFilteredContracts: () => [],
  },

  state: {
    contractList: {
      alreadyAppliedForFilteredContracts: [],
      canApplyForFilteredContracts: [],
      currentRegion: '',
      selectedContracts: [],
      selectedFilters: [],
    },
    contracts: initialContracts,
    fetchContractsRequest: {
      loading: false,
    },
    loading: false,
  },
})

export const ContractsProvider: FC<ContractsProviderProps> = ({ children }) => {
  const [contracts, setContracts] = useState<Contract[]>(initialContracts)
  const [currentRegion, setCurrentRegion] = useState<string>()
  const [selectedContracts, setSelectedContracts] = useState<number[]>([])
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const { userData } = useUserData()

  const [fetchContractsRequest, setFetchContractsRequest] = useState<{
    loading: boolean
  }>({ loading: false })

  /**
   * Get contracts from the API-Endpoint and store them inside
   * this contexts state.
   */
  const fetchContracts = async () => {
    // Do not fetch data, when the sate was already populated.
    if (!!contracts.length) return

    setFetchContractsRequest({ loading: true })

    await api
      .getContracts()
      .then((response) => setContracts(response.data))
      .catch((error) => console.error('failed to get contracts', error))

    setFetchContractsRequest({ loading: false })
  }

  /**
   * Returns the Contract with matching key -> value.
   *
   * @example getContract({ by: 'abbreviation', value: contract.abbreviation }) => Contract
   */
  const getContracts = (props: GetContractsProps) =>
    contracts.filter((contract) => contract[props.by] === props.value)

  /**
   * Return Filtered Contracts for the current region.
   */
  const getFilteredContracts = () => {
    const regionalContracts = contracts.filter(
      (contract) => contract.regionSlug === currentRegion,
    )

    if (!selectedFilters.length) return regionalContracts

    return regionalContracts.filter((regionalContract) =>
      regionalContract.participatingInsuranceCompanies.some(
        (participatingInsuranceCompany) =>
          selectedFilters.includes(participatingInsuranceCompany),
      ),
    )
  }

  /**
   * Get list of selected contract Abbreviations for display
   */
  const getSelectedContractsAbbreviations = (): string[] =>
    contracts
      .filter((contract) => selectedContracts.includes(contract.id))
      .map((contract) => contract.abbreviation)

  /**
   * Return a sub set of selected and filtered contracts.
   */
  const getSubSetOfSelectedAndFilteredContracts = () => {
    const filteredContracts = getFilteredContracts()

    return filteredContracts.filter((filteredContract) =>
      selectedContracts.find(
        (selectedContractId) => selectedContractId === filteredContract.id,
      ),
    )
  }

  /**
   * Remove all Selected Contracts and Filters if regionSlug is not currentRegion
   */
  const _setCurrentRegion = (regionSlug: string) => {
    if (regionSlug !== currentRegion) {
      setSelectedContracts([])
      setSelectedFilters([])
    }

    setCurrentRegion(regionSlug)
  }

  /**
   * Add the provided contractId to the array of selected contract ids or remove it
   * if it was present already.
   */
  const setSelectedContract = (contractId: number) => {
    if (selectedContracts.includes(contractId)) {
      setSelectedContracts(
        selectedContracts.filter(
          (selectedContractId) => selectedContractId !== contractId,
        ),
      )
    } else {
      setSelectedContracts([...selectedContracts, contractId])
    }
  }
  /**
   * if the user is already applied to this contract then it is disabled (Unselectable)
   * @param contract
   */
  const isDisabledContract = (contract: Contract): boolean =>
    !!userData
      ? userData.vertraege.includes(contract.contractIdNum.toString())
      : false

  /**
   * if the user hasn't applied to this contract then it is enabled (Selectable)
   * @param contract
   */
  const isEnabledContract = (contract: Contract): boolean =>
    !isDisabledContract(contract)

  /**
   * Update this contexts loading state when individual
   * request loading states change.
   */
  useEffect(() => {
    setLoading(fetchContractsRequest.loading)
  }, [fetchContractsRequest])

  /**
   * Fetch contracts on initialization.
   */
  useEffect(() => {
    fetchContracts()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const filteredContracts = getFilteredContracts()

  const alreadyAppliedForFilteredContracts =
    filteredContracts.filter(isDisabledContract)
  const canApplyForFilteredContracts =
    filteredContracts.filter(isEnabledContract)

  useEffect(() => {
    if (userData) {
      // in case we have userData, all selectable contracts are selected by default.
      const selectedContractsIds = contracts.reduce((acc, contract) => {
        if (
          contract.regionSlug === currentRegion &&
          isEnabledContract(contract)
        ) {
          return [...acc, contract.id]
        }
        return acc
      }, [] as number[])
      setSelectedContracts(selectedContractsIds)
    }
  }, [userData, contracts, currentRegion]) // eslint-disable-line

  return (
    <ContractsContext.Provider
      value={{
        actions: {
          fetchContracts,
          setCurrentRegion: _setCurrentRegion,
          setSelectedContract,
          setSelectedContracts,
          setSelectedFilters,
        },

        getters: {
          getContracts,
          getFilteredContracts,
          getSelectedContractsAbbreviations,
          getSubSetOfSelectedAndFilteredContracts,
        },

        state: {
          contractList: {
            alreadyAppliedForFilteredContracts,
            canApplyForFilteredContracts,
            currentRegion,
            selectedContracts,
            selectedFilters,
          },
          contracts,
          fetchContractsRequest,
          loading,
        },
      }}
    >
      {children}
    </ContractsContext.Provider>
  )
}

export const ContractsConsumer = ContractsContext.Consumer

export default ContractsContext
