import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    progressIndicator: {
      position: 'absolute',
      right: theme.spacing(1),
    },
  }),

  { name: 'ContactForm' },
)

export default useStyles
