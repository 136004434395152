import type { FC } from 'react'
import React from 'react'
import type { ButtonProps } from '@material-ui/core'
import clsx from 'clsx'

import Button from 'src/components/Button/Button'

import useStyles from './ScrollDown.styles'

export interface ScrollDownProps extends ButtonProps {
  scrollSpeed?: number
}

export const ScrollDown: FC<ScrollDownProps> = ({
  children,
  scrollSpeed = 12,
  ...props
}) => {
  const classes = useStyles()

  const scrollDown = () => {
    const clientHeight = document.documentElement.clientHeight
    let scrolledYOffset: number = -scrollSpeed

    ;(function scroll() {
      if (
        window.pageYOffset < clientHeight &&
        scrolledYOffset < window.pageYOffset
      ) {
        window.scrollBy(0, scrollSpeed)
        scrolledYOffset += scrollSpeed
        setTimeout(scroll, 0)
      }
    })()
  }

  return (
    <Button
      className={clsx(classes.root, classes.scrollDown)}
      onClick={scrollDown}
      {...props}
    >
      {children}
    </Button>
  )
}

export default ScrollDown
