import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    // disable the chip Element from the Select Input when it is "onFocus"
    inputFocused: {
      '&.Mui-focused .MuiChip-root': {
        display: 'none',
      },
    },
  }),

  { name: 'InsuranceFilter' },
)

export default useStyles
