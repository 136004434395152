import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    dialogRoot: {
      padding: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },

    /**
     * The padding must be the same size as the spacing of the grid container,
     * so that the padding and the negative margin balance each other out.
     */
    muiDialogContentRoot: {
      padding: theme.spacing(3),
    },

    modalCloseButton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },

    // the list-style cannot be controlled via the material ui props
    muiListItemRoot: {
      listStyle: 'disc',
      display: 'list-item',
      width: 'auto',
      marginLeft: theme.spacing(3),
    },

    // The icon component has the property color, but cannot access the custom properties like "accent" etc.
    checkIcon: {
      color: theme.palette.accent.main,
    },

    iconAndTextLink: {
      display: 'flex',
      alignItems: 'flex-end',
      cursor: 'pointer',
    },
  }),

  { name: 'ContractCardDialog' },
)

export default useStyles
