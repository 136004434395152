import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    textWrapper: {
      width: 'fit-content',
      margin: 'auto',

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  }),

  { name: 'ContractsView' },
)

export default useStyles
