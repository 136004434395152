import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Hidden, Typography } from '@material-ui/core'
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined'

import { CONTRACTS_VIDEO_URL } from 'src/constants'
import { TestIds } from 'src/testIds'
import useUserData from 'src/hooks/useUserDate'
import Badge from 'src/components/Badge/Badge'
import Button from 'src/components/Button/Button'
import CertificateStripe from 'src/components/CertificateStripe/CertificateStripe'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import CustomIcon from 'src/components/CustomIcon/CustomIcon'
import Image from 'src/components/Image/Image'
import Page from 'src/components/Page/Page'
import RegionSelect from 'src/components/RegionSelect/RegionSelect'
import ScrollDown from 'src/components/ScrollDown/ScrollDown'
import { SsoFetch } from 'src/components/SsoFetch/SsoFetch'
import Modal from 'src/components/VideoModal/VideoModal'

import useStyles from './ContractsView.styles'

const ContractTestIds = TestIds.views.contract

const ContractsView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const { isInitialized } = useUserData()
  return (
    <Page
      className={classes.root}
      data-test-id={ContractTestIds.pageWrapper}
      title={t('Views.Contracts.title')}
    >
      {!isInitialized && <SsoFetch applicationType={'contracts'} />}
      {/* Hero Section */}
      <ContentStripe
        backgroundColor="image"
        backgroundImg="/static/images/hzv-background.jpeg"
        data-test-id={ContractTestIds.heroTeaser}
        isMainContent
        fullHeight
      >
        <Grid
          container
          justifyContent="space-evenly"
          alignContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} md={5}>
            <Box className={classes.textWrapper}>
              <Typography gutterBottom variant="h1">
                {t('Views.Contracts.header')}
              </Typography>

              <Typography gutterBottom>
                {t('Views.Contracts.subheader')}
              </Typography>

              <Button
                disableRipple
                style={{ paddingLeft: 0 }}
                variant="text"
                onClick={() => setIsOpen(true)}
              >
                <PlayCircleFilledOutlinedIcon fontSize="large" />

                <Typography
                  component="span"
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  <Box component="span" ml={1} fontWeight="fontWeightBold">
                    {t('Views.Contracts.link')}
                  </Box>
                </Typography>
              </Button>

              <Modal
                videoSrc={CONTRACTS_VIDEO_URL}
                videoProvider="youTube"
                open={isOpen}
                onClose={() => setIsOpen(false)}
              />

              <Typography>{t('Views.Contracts.paragraph')}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={10} md={5}>
            <Box mb={1}>
              <RegionSelect
                buttonText={t('Views.Contracts.regionButton')}
                context="contracts"
                isDarkBackground
              />
            </Box>

            <Typography variant="subtitle2" align="center">
              {t('Views.Contracts.regionText')}
            </Typography>
          </Grid>

          <Hidden smDown>
            <Grid container justifyContent="center" item xs={12}>
              <ScrollDown>{t('Views.Contracts.button')}</ScrollDown>
            </Grid>
          </Hidden>
        </Grid>
      </ContentStripe>

      {/* Ihre Vorteile */}
      <ContentStripe data-test-id={ContractTestIds.advantagesElement}>
        <Grid
          container
          justifyContent="space-evenly"
          alignContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography color="primary" variant="h2" align="center">
              {t('Views.Contracts.Content.Advantages.header')}
            </Typography>
          </Grid>

          <Grid item xs={10} sm={4}>
            <Box mb={2} textAlign="center">
              <CustomIcon iconName="versorgung" />
            </Box>

            <Box mb={1}>
              <Typography color="primary" variant="h3" align="center">
                {t('Views.Contracts.Content.Advantages.supplySubHeader')}
              </Typography>
            </Box>

            <Typography align="center">
              {t('Views.Contracts.Content.Advantages.supplyText')}
            </Typography>
          </Grid>

          <Grid item xs={10} sm={4}>
            <Box mb={2} textAlign="center">
              <CustomIcon iconName="sicherheit" />
            </Box>

            <Box mb={1}>
              <Typography color="primary" variant="h3" align="center">
                {t('Views.Contracts.Content.Advantages.securitySubHeader')}
              </Typography>
            </Box>

            <Typography align="center">
              {t('Views.Contracts.Content.Advantages.securityText')}
            </Typography>
          </Grid>

          <Grid item xs={10} sm={4}>
            <Box mb={2} textAlign="center">
              <CustomIcon iconName="buerokratie" />
            </Box>

            <Box mb={1}>
              <Typography color="primary" variant="h3" align="center">
                {t('Views.Contracts.Content.Advantages.bureaucracySubHeader')}
              </Typography>
            </Box>

            <Typography align="center">
              {t('Views.Contracts.Content.Advantages.bureaucracyText')}
            </Typography>
          </Grid>
        </Grid>
      </ContentStripe>

      {/* So funktioniert es */}
      <ContentStripe
        backgroundColor="fog"
        data-test-id={ContractTestIds.itWorksElement}
      >
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography color="primary" variant="h2" align="center">
              {t('Views.Contracts.Content.Works.header')}
            </Typography>
          </Grid>

          <Hidden smDown>
            <Grid item md={3}>
              <Box textAlign="right">
                <Image
                  style={{ filter: 'saturate(0)' }}
                  src="/static/images/so-funktioniert-es-hzv.png"
                />
              </Box>
            </Grid>
          </Hidden>

          <Grid item xs={12} md={5}>
            <Box my={3}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Badge badgeContent={1} size="medium" color="primary" />
                </Grid>

                <Grid item xs={8} md={9}>
                  <Typography
                    color="primary"
                    variant="h3"
                    align="left"
                    gutterBottom
                  >
                    {t('Views.Contracts.Content.Works.firstHeader')}
                  </Typography>

                  <Typography align="left">
                    {t('Views.Contracts.Content.Works.firstText')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box my={3}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Badge badgeContent={2} size="medium" color="primary" />
                </Grid>

                <Grid item xs={8} md={9}>
                  <Typography
                    color="primary"
                    variant="h3"
                    align="left"
                    gutterBottom
                  >
                    {t('Views.Contracts.Content.Works.secondHeader')}
                  </Typography>

                  <Typography align="left">
                    {t('Views.Contracts.Content.Works.secondText')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box my={3}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Badge badgeContent={3} size="medium" color="primary" />
                </Grid>

                <Grid item xs={8} md={9}>
                  <Typography
                    color="primary"
                    variant="h3"
                    align="left"
                    gutterBottom
                  >
                    {t('Views.Contracts.Content.Works.thirdHeader')}
                  </Typography>

                  <Typography align="left">
                    {t('Views.Contracts.Content.Works.thirdText')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item lg={12} sm={8}>
            <Box mt={2}>
              <Typography color="primary" variant="h3" align="center">
                {t('Views.Contracts.Content.Works.text')}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Box>
              <RegionSelect
                buttonText={t('Views.Contracts.regionButton')}
                context="contracts"
                inline
              />
            </Box>
          </Grid>
        </Grid>
      </ContentStripe>
      <CertificateStripe />
      <ContactStripe />
    </Page>
  )
}

export default ContractsView
