import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  () => ({
    root: {},

    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    paper: {
      outline: 'none',
      border: 'none',
      maxWidth: '100%',

      '& iframe': {
        maxWidth: '100%',
      },
    },

    vimeo: {
      width: '100%',

      '& iframe': {
        width: '100%',
      },
    },
  }),

  { name: 'VideoModal' },
)

export default useStyles
