import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  () => ({
    root: {},

    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),

  { name: 'ErrorDialog' },
)

export default useStyles
