import type { FC } from 'react'
import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import type { ButtonProps } from '@material-ui/core'
import clsx from 'clsx'

import Button from 'src/components/Button/Button'

import useStyles from './NavItem.styles'

export interface NavItemProps extends ButtonProps<RouterLink> {
  className?: string
  title: string
}

export const NavItem: FC<NavItemProps> = ({ className, title, ...props }) => {
  const classes = useStyles()

  return (
    <Button
      activeClassName={classes.active}
      className={clsx(classes.root, className)}
      component={RouterLink}
      exact
      {...props}
    >
      {title}
    </Button>
  )
}

export default NavItem
