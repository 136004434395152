import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
    },

    logoContainer: {
      margin: theme.spacing(3, 0),
      display: 'flex',
      flexDirection: 'column', // Fixes vertical image stretching in Safari browsers.

      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    logo: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '70%',
      },
    },

    centeredContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    socialIcon: {
      color: theme.palette.common.white,
    },

    navBar: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
      },

      '& .MuiButton-root': {
        color: theme.palette.common.white,
        minWidth: 0,
      },
    },

    spacer: {
      margin: `${theme.spacing(2)}px 0px`,
    },
  }),

  { name: 'Footer' },
)

export default useStyles
