import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import type { DialogProps } from '@material-ui/core'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { TestIds } from 'src/testIds'
import Button from 'src/components/Button/Button'

import useStyles from './ErrorDialog.styles'

const ComponentTestIds = TestIds.components

export interface ContactFormErrorDialogProps
  extends Omit<DialogProps, 'children'> {
  title: string
  errorMessage: string
}

export const ErrorDialog: FC<ContactFormErrorDialogProps> = ({
  onClose,
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog
      data-test-id={ComponentTestIds.errorDialog.wrapper}
      className={classes.modal}
      closeAfterTransition
      onClose={onClose}
      {...props}
    >
      <DialogTitle disableTypography>
        <Typography variant="h2">{props.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Alert severity="error">
          <AlertTitle>{props.errorMessage}</AlertTitle>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('General.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
