import type { ComponentProps, FC } from 'react'
import React from 'react'
import clsx from 'clsx'

import useStyles from './CustomIcon.styles'

export type CustomIconsNameProp =
  | 'interessenvertretung'
  | 'verwaltungskosten'
  | 'vergünstigungen'
  | 'versorgung'
  | 'sicherheit'
  | 'buerokratie'
  | 'region'
  | 'vertraegeAuswaehlen'
  | 'onlineBeantragen'

export interface CustomIconsProps extends ComponentProps<'span'> {
  color?: 'primary' | 'secondary' | 'white'
  iconName: CustomIconsNameProp
}

export const CustomIcon: FC<CustomIconsProps> = ({
  color,
  iconName,
  ...props
}) => {
  const classes = useStyles()
  let icon
  let iconColor

  switch (iconName) {
    case 'interessenvertretung':
      icon = <span>&#xe80c;</span>
      break
    case 'verwaltungskosten':
      icon = <span>&#xe810;</span>
      break
    case 'vergünstigungen':
      icon = <span>&#xe812;</span>
      break
    case 'versorgung':
      icon = <span>&#xe813;</span>
      break
    case 'sicherheit':
      icon = <span>&#xe80d;</span>
      break
    case 'buerokratie':
      icon = <span>&#xe817;</span>
      break
    case 'region':
      icon = <span>&#xe818;</span>
      break
    case 'vertraegeAuswaehlen':
      icon = <span>&#xe814;</span>
      break
    case 'onlineBeantragen':
      icon = <span>&#xe815;</span>
      break
    default:
      icon = <span></span>
  }

  switch (color) {
    case 'primary':
      iconColor = classes.primary
      break
    case 'secondary':
      iconColor = classes.secondary
      break
    case 'white':
      iconColor = classes.white
      break
    default:
      iconColor = classes.primary
  }

  return (
    <span
      className={clsx({
        [classes.root]: true,
        [iconColor]: !!color,
      })}
      {...props}
    >
      {icon}
    </span>
  )
}

export default CustomIcon
