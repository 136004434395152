import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  () => ({
    root: {
      maxWidth: '100%',
    },
  }),

  { name: 'Logo' },
)

export default useStyles
