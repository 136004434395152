import type { FC } from 'react'
import React from 'react'
import type { DrawerProps } from '@material-ui/core'
import { Drawer, List, ListItem } from '@material-ui/core'
import clsx from 'clsx'

import type { NavItemProps } from 'src/components/NavItem/NavItem'
import NavItem from 'src/components/NavItem/NavItem'

import useStyles from './NavDrawer.styles'

export interface NavDrawerProps extends DrawerProps {
  className?: string
  navItems: NavItemProps[]
}

export const NavDrawer: FC<NavDrawerProps> = ({
  className,
  navItems,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Drawer anchor="top" className={clsx(classes.root, className)} {...props}>
      <List component="nav">
        {navItems.map((navItem) => (
          <ListItem key={navItem.title}>
            <NavItem title={navItem.title} to={navItem.to} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default NavDrawer
