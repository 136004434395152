import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    scrollDown: {
      textTransform: 'none',
      color: theme.palette.common.white,
      fontSize: '18px',

      '&:hover': {
        backgroundColor: 'inherit',
      },

      '&:after': {
        content: '""',
        border: 'solid white',
        borderWidth: '0 3px 3px 0',
        display: 'inline-block',
        padding: '3px',
        transform: 'rotate(45deg) translateX(-50%)',
        position: 'absolute',
        top: '90%',
        left: '50%',
        marginRight: '45px',
        textAlign: 'center',
        transition: 'top 0.5s ease',
      },

      '&:hover:after': {
        top: '120%',
      },
    },
  }),

  { name: 'ScrollDown' },
)

export default useStyles
