declare global {
  interface Window {
    env: any
  }
}

function readEnv(name: string) {
  if (window.env && window.env[name]) {
    return window.env[name]
  } else {
    return process.env[name]
  }
}

export const BACKEND_URL = readEnv('REACT_APP_BACKEND_URL')
export const ENVIRONMENT = readEnv('REACT_APP_ENVIRONMENT')
export const SENTRY_URL = readEnv('REACT_APP_SENTRY_URL')

export const API_URL = `${BACKEND_URL}/api/v1/`
export const ssoLoginUrl = (applicationType: 'contracts' | 'membership') =>
  BACKEND_URL + '/sso/v1/auth/' + applicationType

export const PAGE_TITLE = 'Hausärztinnen- und Hausärzteverband'

export const CONTRACTS_VIDEO_URL = 'https://www.youtube.com/embed/OHXmc93QGDk'
export const MEMBERSHIP_VIDEO_URL = 'https://player.vimeo.com/video/147319274'

export const THEMES = { DARK: 'DARK', LIGHT: 'LIGHT' }

export const SHOW_SSO_BANNER = readEnv('REACT_APP_SHOW_SSO_BANNER') === 'true'
