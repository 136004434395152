import type { FC, ReactNode } from 'react'
import React, { createContext, useEffect, useState } from 'react'

import type { Region } from 'src/types'
import api from 'src/services/api'

export interface RegionsProviderProps {
  children?: ReactNode
}
export interface GetRegionProps {
  by: keyof Region
  value: string
}

const initialRegions: Region[] = []

export interface RegionsContextValue {
  actions: {
    fetchRegions: () => Promise<void>
  }

  getters: {
    getRegion: (props: GetRegionProps) => Region | undefined
  }

  state: {
    regions: Region[]
    fetchRegionsRequest: {
      loading: boolean
    }
    loading: boolean
  }
}
const RegionsContext = createContext<RegionsContextValue>({
  actions: {
    fetchRegions: () => new Promise(() => {}),
  },

  getters: {
    getRegion: () => undefined,
  },

  state: {
    fetchRegionsRequest: {
      loading: false,
    },
    loading: false,
    regions: initialRegions,
  },
})

export const RegionsProvider: FC<RegionsProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [regions, setRegions] = useState<Region[]>(initialRegions)

  const [fetchRegionsRequest, setFetchRegionsRequest] = useState<{
    loading: boolean
  }>({ loading: false })

  /**
   * Get regions from the API-Endpoint and store them inside
   * this contexts state.
   */
  const fetchRegions = async () => {
    // Do not fetch data, when the sate was already populated.
    if (!!regions.length) return

    setFetchRegionsRequest({ loading: true })

    await api
      .getRegions()
      .then((response) => setRegions(response.data))
      .catch((error) => console.error('failed to get regions', error))

    setFetchRegionsRequest({ loading: false })
  }

  /**
   * Returns the region with matching key -> value.
   *
   * @example getRegion({ by: 'okv', value: region.okv }) => Region
   */
  const getRegion = (props: GetRegionProps) =>
    regions.find((region) => region[props.by] === props.value)

  /**
   * Update this contexts loading state when individual
   * request loading states change.
   */
  useEffect(() => {
    setLoading(fetchRegionsRequest.loading)
  }, [fetchRegionsRequest])

  /**
   * Fetch regions on initialization.
   */
  useEffect(() => {
    fetchRegions()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RegionsContext.Provider
      value={{
        actions: {
          fetchRegions,
        },

        getters: {
          getRegion,
        },

        state: {
          fetchRegionsRequest,
          loading,
          regions,
        },
      }}
    >
      {children}
    </RegionsContext.Provider>
  )
}

export const RegionsConsumer = RegionsContext.Consumer

export default RegionsContext
