import type { FC } from 'react'
import React from 'react'
import type { ReactMarkdownOptions } from 'react-markdown'
import ReactMarkdown from 'react-markdown'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './Markdown.styles'

export interface MarkdownProps extends ReactMarkdownOptions {}

export const Markdown: FC<MarkdownProps> = ({
  children,
  className,
  ...props
}) => {
  const classes = useStyles()

  const MarkdownPTag: FC = ({ children, ...props }) => (
    <Typography gutterBottom {...props}>
      {children}
    </Typography>
  )
  const MarkdownH1Tag: FC = ({ children, ...props }) => (
    <Typography gutterBottom variant="h1" {...props}>
      {children}
    </Typography>
  )
  const MarkdownH2Tag: FC = ({ children, ...props }) => (
    <Typography gutterBottom variant="h2" {...props}>
      {children}
    </Typography>
  )
  const MarkdownH3Tag: FC = ({ children, ...props }) => (
    <Typography gutterBottom variant="h3" {...props}>
      {children}
    </Typography>
  )
  const MarkdownH4Tag: FC = ({ children, ...props }) => (
    <Typography gutterBottom variant="h4" {...props}>
      {children}
    </Typography>
  )
  const MarkdownUlTag: FC = ({ children, ...props }) => (
    <ul {...props}>{children}</ul>
  )
  const MarkdownOlTag: FC = ({ children, ...props }) => (
    <ol {...props}>{children}</ol>
  )
  const MarkdownLiTag: FC = ({ children, ...props }) => (
    <li>
      <Typography gutterBottom {...props}>
        {children}
      </Typography>
    </li>
  )

  /**
   * so that the mui tags are used instead of the default html tags,
   * the mui tags must be assigned to the default tags.
   */
  const markdownComponentConfiguration = {
    h1: MarkdownH1Tag,
    h2: MarkdownH2Tag,
    h3: MarkdownH3Tag,
    h4: MarkdownH4Tag,
    li: MarkdownLiTag,
    ol: MarkdownOlTag,
    p: MarkdownPTag,
    ul: MarkdownUlTag,
  }

  return (
    <ReactMarkdown
      className={clsx(classes.root, className)}
      components={markdownComponentConfiguration}
      {...props}
    >
      {children}
    </ReactMarkdown>
  )
}

export default Markdown
