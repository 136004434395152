import type { ElementType, ForwardedRef } from 'react'
import React from 'react'
import type { ButtonProps as MUIButtonProps } from '@material-ui/core'
import { Button as MUIButton } from '@material-ui/core' // eslint-disable-line no-restricted-imports
import { Omit } from '@material-ui/types'
import clsx from 'clsx'

import useStyles from './Button.styles'

/**
 * @property color
 *
 *  Default: 'inherit', 'primary', 'secondary', 'default'
 *
 *  Custom: 'tertiary'
 */
export interface ButtonProps
  extends Omit<MUIButtonProps<ElementType>, 'color'> {
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | 'tertiary'
}

export const Button = React.forwardRef<HTMLElement, ButtonProps>(
  ({ children, color, ...props }, ref) => {
    const classes = useStyles()
    // don't pass `color` prop to MUI Button for custom colors
    const muiColorProp = color === 'tertiary' ? undefined : color

    return (
      <MUIButton
        ref={ref as ForwardedRef<HTMLButtonElement>}
        classes={{
          // set custom styles depending on the color
          contained: clsx({
            [classes.secondary]: color === 'secondary',
            [classes.tertiary]: color === 'tertiary',
          }),
        }}
        color={muiColorProp}
        {...props}
      >
        {children}
      </MUIButton>
    )
  },
)

export default Button
