import { TFunction } from 'i18next'

/**
 @description Extracts possibly nested array of objects instead of strings using the translation function.
 */
const tArray = (
  t: TFunction<any>,
  key?: string,
  options?: any,
): any[] | undefined => {
  // @ts-ignore
  const result = t(key, {
    returnObjects: true,
    ...options,
  })

  if (Array.isArray(result)) {
    return result
  }

  return [result]
}

export default tArray
