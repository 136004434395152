export const scrollTo = (elementId: string) => {
  const element = document.getElementById(elementId)
  element &&
    window.setTimeout(() => {
      const yOffset = -50
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset

      window.scrollTo({ behavior: 'smooth', top: y })
    }, 100)
}

export const customRegExp = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
}
