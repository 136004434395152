import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontWeight: theme.typography.fontWeightBold as number | undefined,
      paddingLeft: 18,
      paddingRight: 18,
    },

    active: {
      color: theme.palette.primary.main,
    },
  }),

  { name: 'NavItem' },
)

export default useStyles
