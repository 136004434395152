import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    column: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flexGrow: 1,
    },

    columnLeft: {
      [theme.breakpoints.up('sm')]: {
        textAlign: 'start',
        alignItems: 'flex-start',
      },
    },

    columnRight: {
      [theme.breakpoints.up('sm')]: {
        textAlign: 'end',
        alignItems: 'flex-end',
      },
    },
  }),

  { name: 'ContactStripe' },
)

export default useStyles
