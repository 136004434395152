import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import Page from 'src/components/Page/Page'

import { TestIds } from '../../testIds'

import useStyles from './ErrorView.styles'

const ErrorViewTestIds = TestIds.views.errorView

const ErrorView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const params = useParams<{ errorCode: string }>()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  })

  const title =
    params.errorCode === '404'
      ? t('Views.ErrorPage.NotFound.title')
      : params.errorCode === '403'
      ? t('Views.ErrorPage.Forbidden.title')
      : params.errorCode === '401'
      ? t('Views.ErrorPage.Unauthorized.title')
      : t('Views.ErrorPage.UnknownError.title')

  return (
    <Page
      data-test-id={ErrorViewTestIds.pageWrapper}
      className={classes.error}
      title={title}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          color="primary"
          variant={smDown ? 'h4' : 'h2'}
        >
          {title}
        </Typography>
      </Container>
    </Page>
  )
}

export default ErrorView
