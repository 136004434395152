import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Hidden,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import clsx from 'clsx'

import { TestIds } from 'src/testIds'
import { SpecialContract } from 'src/types'
import { withHttps } from 'src/utils'
import Button from 'src/components/Button/Button'
import SpecialContractCardDialog from 'src/components/ContractCardDialog/SpecialContractCardDialog'
import Image from 'src/components/Image/Image'

import useStyles from './ContractCard.styles'

const ComponentTestIds = TestIds.components

export interface SpecialContractCardProps {
  specialContract: SpecialContract
  regionName: string | undefined
}

/**
 * This Card Component has a mobile and a desktop version.
 * The views differ too much from each other, so that 2 variants are necessary.
 */
export const SpecialContractCard: FC<SpecialContractCardProps> = ({
  specialContract,
  regionName,
  ...props
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })

  const [openModal, setOpenModal] = useState<boolean>()

  const Img = (
    <Image
      className={clsx(classes.cardImg)}
      src={specialContract.logoUrl || ''}
      alt={specialContract.abbreviation}
    />
  )

  const ButtonGroup = (
    <Grid spacing={1} container>
      <Grid item xs={12} sm>
        <Button
          data-test-id={
            ComponentTestIds.specialContractCard.dialogDetailsButton
          }
          color={smUp ? 'secondary' : undefined}
          fullWidth
          size="small"
          variant={smUp ? 'contained' : 'outlined'}
          onClick={() => setOpenModal(true)}
        >
          {t('ContractCard.buttonDetails')}
        </Button>
      </Grid>
    </Grid>
  )

  return (
    <Card className={clsx({ [classes.root]: true })} {...props}>
      <Hidden xsDown>
        <CardHeader
          classes={{
            action: classes.cardHeaderAction,
            root: classes.cardHeaderRoot,
          }}
          avatar={<Typography>{specialContract.abbreviation}</Typography>}
          action={ButtonGroup}
        />

        <CardContent component={Grid} container justifyContent="space-between">
          <Grid item sm={7}>
            <Grid item sm={12}>
              <Typography>{specialContract.teaser}</Typography>
            </Grid>
            <Link
              variant="subtitle1"
              rel="noreferrer"
              target="_blank"
              href={withHttps(specialContract.link1)}
            >
              {specialContract.linkText1}
            </Link>
          </Grid>
          <Grid item sm={2}>
            {Img}
          </Grid>
        </CardContent>
      </Hidden>

      <Hidden smUp={smUp}>
        <CardContent>
          <Grid container>
            <Grid item xs>
              {Img}
            </Grid>
            <Grid container alignItems="flex-end" direction="column" item xs>
              <Grid item>
                <Typography>{specialContract.abbreviation}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>{ButtonGroup}</CardActions>
      </Hidden>

      <SpecialContractCardDialog
        data-test-id={TestIds.components.specialContractDialog}
        contract={specialContract}
        contractLogo={Img}
        open={!!openModal}
        onClose={() => setOpenModal(false)}
        regionName={regionName}
      />
    </Card>
  )
}

export default SpecialContractCard
