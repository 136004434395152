import type { FC, ReactNode } from 'react'
import React from 'react'
import { Box } from '@material-ui/core'

import useScrollReset from 'src/hooks/useScrollReset'
import Footer from 'src/components/Footer/Footer'
import TopBar from 'src/components/TopBar/TopBar'

import useStyles from './HeaderFooterFullWidthLayout.styles'

export interface HeaderFooterFullWidthLayoutProps {
  children?: ReactNode
}

export const HeaderFooterFullWidthLayout: FC<
  HeaderFooterFullWidthLayoutProps
> = ({ children }) => {
  const classes = useStyles()

  useScrollReset()

  return (
    <Box className={classes.root}>
      <TopBar elevation={0} position="relative" />

      <div className={classes.content}>{children}</div>

      <Footer />
    </Box>
  )
}

export default HeaderFooterFullWidthLayout
