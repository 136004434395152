import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
    },

    toolbar: {
      height: theme.settings.topBarHeight,
      borderBottom: `1px ${theme.palette.grey['300']} solid`,
    },

    navBarContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
    },

    drawer: {
      position: 'absolute',
    },

    drawerAnchor: {
      top: theme.settings.topBarHeight,
    },

    // Override drawer modal postion and z-index, so it is displayed beneath the top bar.
    drawerModal: {
      position: [['absolute'], '!important'] as any,
      zIndex: [[theme.zIndex.appBar - 1], '!important'] as any,
    },
  }),
  { name: 'TopBar' },
)

export default useStyles
