import { useContext } from 'react'

import type { ReleaseContextValue } from 'src/contexts/ReleaseContext'
import ReleaseContext from 'src/contexts/ReleaseContext'

/**
 * @description Provides the ReleaseContext.
 */
const useRelease = (): ReleaseContextValue => useContext(ReleaseContext)

export default useRelease
