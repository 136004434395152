import type { FC } from 'react'
import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'

import useStyles from './LoadingScreen.styles'

export const LoadingScreen: FC = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <CircularProgress size={80} />
    </Box>
  )
}

export default LoadingScreen
