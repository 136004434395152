import type { FC, ReactNode } from 'react'
import React, { createContext, useEffect, useState } from 'react'

import type { PreviewParams, Release } from 'src/types'
import { getPreviewParams } from 'src/utils'
import api from 'src/services/api'

export interface ReleaseContextProps {
  children?: ReactNode
}

export interface ReleaseContextValue {
  actions: {
    fetchRelease: () => Promise<void>
  }

  state: {
    release: Release | undefined
    fetchReleaseRequest: {
      loading: boolean
    }
    isValidRelease: boolean
    loading: boolean
    previewParams: PreviewParams | undefined
  }
}
const ReleaseContext = createContext<ReleaseContextValue>({
  actions: {
    fetchRelease: () => new Promise(() => {}),
  },

  state: {
    fetchReleaseRequest: {
      loading: false,
    },
    isValidRelease: true,
    loading: false,
    previewParams: undefined,
    release: undefined,
  },
})

export const ReleaseProvider: FC<ReleaseContextProps> = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [release, setRelease] = useState<Release | undefined>()
  const [isValidRelease, setIsValidRelease] = useState(true)
  const [previewParams, setPreviewParams] = useState<
    PreviewParams | undefined
  >()
  const [fetchReleaseRequest, setFetchReleaseRequest] = useState<{
    loading: boolean
  }>({ loading: false })

  /**
   * Get regions from the API-Endpoint and store them inside
   * this contexts state.
   */
  const fetchRelease = async () => {
    // Do not fetch data, when the sate was already populated.
    if (!!release) return

    setFetchReleaseRequest({ loading: true })

    await api
      .getRelease()
      .then((response) => {
        const { data: _release } = response

        setRelease(_release)
        setIsValidRelease(!!_release.id)
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null

        if (statusCode === 400) {
          setIsValidRelease(false)
        }

        console.error('failed to get release', error)
      })

    setFetchReleaseRequest({ loading: false })
  }

  /**
   * Update this contexts loading state when individual
   * request loading states change.
   */
  useEffect(() => {
    setLoading(fetchReleaseRequest.loading)
  }, [fetchReleaseRequest])

  /**
   * Fetch regions on initialization.
   */
  useEffect(() => {
    setPreviewParams(getPreviewParams(window.location))
    fetchRelease()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReleaseContext.Provider
      value={{
        actions: {
          fetchRelease,
        },

        state: {
          fetchReleaseRequest,
          isValidRelease,
          loading,
          previewParams,
          release,
        },
      }}
    >
      {children}
    </ReleaseContext.Provider>
  )
}

export const ReleaseConsumer = ReleaseContext.Consumer

export default ReleaseContext
