import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: `${theme.spacing(3)}px 0`,
      // "zIndex" is necessary
      zIndex: 1,
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderTop: '3px solid',
      borderColor: theme.palette.accent.main,
    },
    uppercase: {
      textTransform: 'uppercase',
    },
  }),
  { name: 'Basket' },
)

export default useStyles
