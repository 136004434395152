import { isValid, parse } from 'date-fns'
import { de } from 'date-fns/locale'

export function parsedDate(value: string) {
  const parsed = parse(value || '', 'dd.MM.yyyy', new Date(), { locale: de })
  return isValid(parsed) ? parsed : null
}

export function isValidDateString(value: string) {
  const regex = new RegExp('^\\d{2}\\.\\d{2}\\.\\d{4}$')
  return regex.test(value)
}
