import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      maxWidth: 275,
    },
    content: {
      minHeight: 100,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    badgeContent: {
      backgroundColor: theme.palette.accent.main,
      height: 23,
      minWidth: 23,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),

  { name: 'Step' },
)

export default useStyles
