import type { FC } from 'react'
import React from 'react'
import type { DialogProps } from '@material-ui/core'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'

import { SpecialContract } from 'src/types'
import { openInNewTab, withHttps } from 'src/utils'

import useStyles from './ContractCardDialog.styles'

export interface SpecialContractCardDialogProps extends DialogProps {
  contract: SpecialContract
  contractLogo: JSX.Element
  regionName: string | undefined
}

export const SpecialContractCardDialog: FC<SpecialContractCardDialogProps> = ({
  contract,
  contractLogo,
  onClose,
  regionName,
  ...props
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })

  return (
    <Dialog
      classes={{ paper: clsx(classes.dialogRoot) }}
      fullScreen={!smUp}
      maxWidth="md"
      onClose={onClose}
      scroll="paper"
      {...props}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          {onClose && (
            <Grid item xs={12}>
              <IconButton
                onClick={(event) => onClose(event, 'backdropClick')}
                className={clsx(classes.modalCloseButton)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={12} sm={8}>
            <Typography color="primary" variant="h2">
              {contract.abbreviation} in {regionName}
            </Typography>
            <Typography>{contract.teaser}</Typography>
          </Grid>
          {smUp && (
            <Grid item sm={3}>
              {contractLogo}
            </Grid>
          )}
        </Grid>
      </DialogTitle>

      <DialogContent classes={{ root: clsx(classes.muiDialogContentRoot) }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Link
              className={clsx(classes.iconAndTextLink)}
              color="primary"
              onClick={() => openInNewTab(withHttps(contract.link1))}
              underline="none"
              variant="subtitle2"
            >
              <ChevronRightIcon fontSize="small" color="primary" />
              {contract.linkText1 ? contract.linkText1 : contract.link1}
            </Link>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default SpecialContractCardDialog
