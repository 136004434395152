import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
  }),

  { name: 'ContractFormView' },
)

export default useStyles
