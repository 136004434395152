import type { ChangeEvent, FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import clsx from 'clsx'

import { TestIds } from 'src/testIds'
import { Contract } from 'src/types'
import Button from 'src/components/Button/Button'
import ContractCardDialog from 'src/components/ContractCardDialog/ContractCardDialog'
import Image from 'src/components/Image/Image'

import useStyles from './ContractCard.styles'

const ComponentTestIds = TestIds.components
export interface ContractCardProps {
  checked: boolean | undefined
  contract: Contract
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  regionName: string | undefined
  disabled?: boolean
}

/**
 * This Card Component has a mobile and a desktop version.
 * The views differ too much from each other, so that 2 variants are necessary.
 */
export const ContractCard: FC<ContractCardProps> = ({
  checked,
  contract,
  onChange,
  disabled = false,
  regionName,
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })

  const [openModalWith, setOpenModalWith] = useState<
    'details' | 'insurance' | 'software' | undefined
  >()

  const Img = (
    <Image
      className={clsx(classes.cardImg)}
      src={contract.logoUrl || ''}
      alt={contract.abbreviation}
    />
  )

  const ButtonGroup = (
    <Grid spacing={1} container>
      <Grid item xs={12} sm>
        <Button
          data-test-id={ComponentTestIds.contractCard.dialogDetailsButton}
          color={smUp ? 'secondary' : undefined}
          fullWidth
          size="small"
          variant={smUp ? 'contained' : 'outlined'}
          disabled={disabled}
          onClick={() => setOpenModalWith('details')}
        >
          {t('ContractCard.buttonDetails')}
        </Button>
      </Grid>

      <Grid item xs={12} sm>
        <Button
          data-test-id={ComponentTestIds.contractCard.dialogInsuranceButton}
          color={smUp ? 'secondary' : undefined}
          fullWidth
          size="small"
          disabled={disabled}
          variant={smUp ? 'contained' : 'outlined'}
          onClick={() => setOpenModalWith('insurance')}
        >
          {t('ContractCard.buttonInsurance')}
        </Button>
      </Grid>

      <Grid item xs={12} sm>
        <Button
          data-test-id={ComponentTestIds.contractCard.dialogSoftwareButton}
          color={smUp ? 'secondary' : undefined}
          fullWidth
          size="small"
          variant={smUp ? 'contained' : 'outlined'}
          disabled={disabled}
          onClick={() => setOpenModalWith('software')}
        >
          {t('ContractCard.buttonSoftware')}
        </Button>
      </Grid>
    </Grid>
  )

  return (
    <Card
      className={clsx({
        [classes.root]: true,
        [classes.checked]: checked,
        [classes.disabled]: disabled,
      })}
      {...props}
    >
      <Hidden xsDown>
        <CardHeader
          classes={{
            action: classes.cardHeaderAction,
            root: classes.cardHeaderRoot,
          }}
          avatar={
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={checked}
                    name={contract.abbreviation}
                    onChange={onChange}
                    value={contract.id}
                  />
                }
                label={contract.abbreviation}
              />
            </FormGroup>
          }
          action={ButtonGroup}
        />

        <CardContent component={Grid} container justifyContent="space-between">
          <Grid item sm={7}>
            <Typography>{contract.teaserText}</Typography>
          </Grid>

          <Grid item sm={2}>
            {Img}
          </Grid>
        </CardContent>
      </Hidden>

      <Hidden smUp>
        <CardContent>
          <Grid container>
            <Grid item xs>
              {Img}
            </Grid>

            <Grid container alignItems="flex-end" direction="column" item xs>
              <Grid item>
                <Switch
                  disabled={disabled}
                  checked={checked}
                  classes={{ root: clsx(classes.switchRoot) }}
                  name={contract.abbreviation}
                  onChange={onChange}
                  value={contract.id}
                />
              </Grid>

              <Grid item>
                <Typography>{contract.abbreviation}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>{ButtonGroup}</CardActions>
      </Hidden>

      <ContractCardDialog
        data-test-id={TestIds.components.contractDialog}
        contract={contract}
        contractLogo={Img}
        open={!!openModalWith}
        openModalWith={openModalWith}
        onClose={() => setOpenModalWith(undefined)}
        regionName={regionName}
      />
    </Card>
  )
}

export default ContractCard
