import type { FC } from 'react'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { de, enIN } from 'date-fns/locale'
import { create } from 'jss'
import { SnackbarProvider } from 'notistack'

import { THEMES } from 'src/constants'
import routes, { renderRoutes } from 'src/routes'
import { createTheme } from 'src/theme'
import i18n from 'src/services/i18n/i18n'
import { ContractsProvider } from 'src/contexts/ContractsContext'
import { MuiConfirmProvider } from 'src/contexts/MuiConfirmContext'
import { RegionsProvider } from 'src/contexts/RegionsContext'
import { ReleaseProvider } from 'src/contexts/ReleaseContext'
import { UserDataProvider } from 'src/contexts/UserDataContext'

import useStyles from './App.styles'

const App: FC = () => {
  const locales: { [key: string]: Locale } = { de, en: enIN }
  const jss = create({ id: { minify: false }, plugins: jssPreset().plugins })

  const theme = createTheme({
    responsiveFontSizes: true,
    theme: THEMES.LIGHT,
  })

  useStyles()

  return (
    <Router>
      <UserDataProvider>
        <ReleaseProvider>
          <ContractsProvider>
            <RegionsProvider>
              <ThemeProvider theme={theme}>
                <StylesProvider jss={jss}>
                  <MuiConfirmProvider>
                    <MuiPickersUtilsProvider
                      locale={locales[i18n.language]}
                      utils={DateFnsUtils}
                    >
                      <SnackbarProvider maxSnack={3}>
                        {renderRoutes(routes)}
                      </SnackbarProvider>
                    </MuiPickersUtilsProvider>
                  </MuiConfirmProvider>
                </StylesProvider>
              </ThemeProvider>
            </RegionsProvider>
          </ContractsProvider>
        </ReleaseProvider>
      </UserDataProvider>
    </Router>
  )
}

export default App
