import type { FC } from 'react'
import React from 'react'
import type { ModalProps as MUIModalProps } from '@material-ui/core'
import { Modal as MUIModal } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './VideoModal.styles'

export interface VideoModalProps extends Omit<MUIModalProps, 'children'> {
  className?: string
  title?: string
  videoSrc: string
  videoProvider: 'youTube' | 'vimeo'
}

export const VideoModal: FC<VideoModalProps> = ({
  title = 'Haso Video',
  videoSrc,
  videoProvider,
  ...props
}) => {
  const classes = useStyles()

  return (
    <MUIModal className={classes.modal} closeAfterTransition {...props}>
      <div
        className={clsx(classes.paper, {
          [classes.vimeo]: videoProvider === 'vimeo',
        })}
      >
        <iframe
          allow="autoplay; fullscreen; picture-in-picture"
          frameBorder="0"
          height="360"
          src={videoSrc}
          title={title}
          width="640"
        />
      </div>
    </MUIModal>
  )
}

export default VideoModal
