import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  () => ({
    root: {},
  }),

  { name: 'NavDrawer' },
)

export default useStyles
