import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    headerRoot: {},
    listRoot: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold as number,
    },
    uppercase: {
      textTransform: 'uppercase',
    },
  }),

  { name: 'ContractList' },
)

export default useStyles
