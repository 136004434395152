import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import translationsDe from './languages/de/translations.json'
import translationsEn from './languages/en/translations.json'

i18n
  .use(initReactI18next) // Pass the i18n instance to react-i18next.
  .init({
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // Not needed for react as it escapes by default.
    },

    lng: 'de',

    resources: {
      de: translationsDe,
      en: translationsEn,
    },
  })

export default i18n
