import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  generatePath,
  NavLink as RouterLink,
  useParams,
} from 'react-router-dom'
import {
  Box,
  capitalize,
  ClickAwayListener,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import routes from 'src/routes'
import Button from 'src/components/Button/Button'

import useStyles, { useTooltipStyles } from './Stepper.styles'

export interface ContractFormStepperType {
  start: { label: string; sublabel?: string }
  end: {
    label: string
    sublabel?: string
    selectedContractsAbbreviations?: string[]
  }
  active: 'start' | 'end'
}

export const ContractFormStepper = ({
  start,
  end,
  active,
}: ContractFormStepperType) => {
  const classes = useStyles()
  const tooltipClasses = useTooltipStyles()
  const [open, setOpen] = React.useState<boolean>(false)
  const { t } = useTranslation()
  const params = useParams<{ regionSlug: string }>()

  const regionContractsPath = generatePath(
    routes.root.routes!.contracts.routes!.contractList.path,
    {
      regionSlug: params.regionSlug,
    },
  )
  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const isStartStep = active === 'start'
  const isEndStep = active === 'end'

  return (
    <ol className={classes.root}>
      <li
        className={isEndStep ? classes.inActive : classes.active}
        aria-current={isStartStep}
      >
        <Button
          variant={'text'}
          color={'primary'}
          component={RouterLink}
          underline="none"
          className={classes.buttonLabel}
          to={regionContractsPath}
          startIcon={isEndStep ? <ArrowBackIcon /> : undefined}
        >
          {capitalize(start.label)}
        </Button>
        {isStartStep && start.sublabel && (
          <Typography
            color={'primary'}
            variant={'body2'}
            className={classes.bold}
            data-test-id={'stepper-start-sub-label'}
          >
            {capitalize(start.sublabel)}
          </Typography>
        )}
      </li>
      <li className={classes.arrow} aria-hidden={true} />
      <li
        className={isStartStep ? classes.inActive : classes.active}
        aria-current={isEndStep}
      >
        <Typography color={'primary'} variant={'body1'}>
          {capitalize(end.label)}
        </Typography>
        {isEndStep && end.sublabel && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              interactive
              arrow
              title={
                <Box
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  aria-label={t('Views.ContractForm.contractList')}
                >
                  {end?.selectedContractsAbbreviations?.length ? (
                    end?.selectedContractsAbbreviations
                      .sort((abbreviationA, abbreviationB) =>
                        abbreviationA.localeCompare(abbreviationB, undefined, {
                          sensitivity: 'base',
                        }),
                      )
                      .map((str) => (
                        <Typography
                          variant={'overline'}
                          component={'p'}
                          align={'center'}
                          color={'primary'}
                          key={str}
                        >
                          {str}
                        </Typography>
                      ))
                  ) : (
                    <Typography
                      variant={'overline'}
                      color={'primary'}
                      align={'center'}
                    >
                      {t('Views.ContractForm.noSelectedContract')}
                    </Typography>
                  )}
                </Box>
              }
              classes={tooltipClasses}
            >
              <Button
                onClick={handleTooltipOpen}
                onMouseOver={handleTooltipOpen}
                className={`${classes.buttonLabel} ${classes.bold}`}
                variant={'text'}
                color={'primary'}
              >
                {capitalize(end.sublabel)}
              </Button>
            </Tooltip>
          </ClickAwayListener>
        )}
      </li>
    </ol>
  )
}
