import type { FC, ReactNode } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import routes from 'src/routes'
import useUserData from 'src/hooks/useUserDate'

interface MemvershipGuard {
  children?: ReactNode
}

const MembershipGuard: FC<MemvershipGuard> = ({ children }) => {
  const { hasMembershipStatus } = useUserData()
  const { pathname } = useLocation<{
    pathname?: string
  }>()

  if (hasMembershipStatus) {
    const redirectUrl = pathname.replace(
      routes.root.routes.membership.path,
      routes.root.routes.contracts.path,
    )
    return <Redirect to={redirectUrl} />
  }
  return <>{children}</>
}

export default MembershipGuard
