import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    '@font-face': {
      fontFamily: 'fontello',
      src: 'url(/static/fonts/fontello.woff2) format("woff2")',
    },
    root: {
      fontSize: '45px',
      fontFamily: 'fontello',
      color: theme.palette.primary.main,
    },
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    white: {
      color: theme.palette.common.white,
    },
  }),
  { name: 'CustomIcon' },
)

export default useStyles
