import { colors } from '@material-ui/core'

const typography = {
  fontFamily: 'PT Sans',
  fontWeightRegular: 400,
  fontWeightBold: 700,
  fontSize: 16,

  h1: {
    get fontWeight() {
      return typography.fontWeightRegular
    },
    fontSize: 49,
  },

  h2: {
    get fontWeight() {
      return typography.fontWeightRegular
    },
    fontSize: 29,
  },

  h3: {
    get fontWeight() {
      return typography.fontWeightRegular
    },
    fontSize: 24,
  },

  h4: {
    get fontWeight() {
      return typography.fontWeightRegular
    },
    fontSize: 20,
  },

  capital: {
    get fontWeight() {
      return typography.fontWeightRegular
    },
    marginTop: 20,
    fontSize: 20,
  },

  h5: {
    get fontWeight() {
      return typography.fontWeightRegular
    },
    fontSize: 16,
  },

  h6: {
    get fontWeight() {
      return typography.fontWeightRegular
    },
    fontSize: 14,
  },

  subtitle2: {
    get fontWeight() {
      return typography.fontWeightRegular
    },
    fontSize: 13,
  },

  caption: {
    color: colors.grey[600],
    fontSize: 12,
  },

  overline: {
    get fontWeight() {
      return typography.fontWeightRegular
    },
  },
}

export default typography
