import i18n from 'i18next'
import * as Yup from 'yup'

export const validateLanrId = Yup.string()
  .required(i18n.t('ContractForm.Fields.PersonalData.LanrId.required'))
  .min(7, i18n.t('ContractForm.Fields.PersonalData.LanrId.min'))
  .max(9, i18n.t('ContractForm.Fields.PersonalData.LanrId.max'))
  .matches(/^\d+$/, i18n.t('ContractForm.Fields.PersonalData.LanrId.type'))

export const validateFirstName = Yup.string()
  .required(i18n.t('ContractForm.Fields.PersonalData.FirstName.required'))
  .min(2, i18n.t('General.Form.minLength', { length: 2 }))
  .max(100, i18n.t('General.Form.maxLength', { length: 100 }))

export const validateLastName = Yup.string()
  .required(i18n.t('ContractForm.Fields.PersonalData.LastName.required'))
  .min(2, i18n.t('General.Form.minLength', { length: 2 }))
  .max(100, i18n.t('General.Form.maxLength', { length: 100 }))
