import { useContext } from 'react'

import type { RegionsContextValue } from 'src/contexts/RegionsContext'
import RegionsContext from 'src/contexts/RegionsContext'

/**
 * @description Provides the RegionsContext.
 */
const useRegions = (): RegionsContextValue => useContext(RegionsContext)

export default useRegions
