import React, { ReactNode } from 'react'
import { Divider, Grid, useTheme } from '@material-ui/core'

export interface DividerWithTextProps {
  children: ReactNode
}

const DividerWithText = ({ children, ...props }: DividerWithTextProps) => {
  const theme = useTheme()
  return (
    <Grid
      container
      alignItems="center"
      spacing={3}
      style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(2) }}
      {...props}
    >
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item>{children}</Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  )
}

export default DividerWithText
