import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    fieldset: {
      width: '100%',
      padding: 0,
      border: 'none',
      margin: 0,
    },

    accordionSummaryContent: {},

    accordionTitle: {
      marginTop: theme.spacing(0.35),
    },

    regionalMembershipCard: {
      cursor: 'pointer',
    },

    checked: {
      boxShadow: `0 0 3px 2px ${theme.palette.accent.main}`,
    },

    uppercase: {
      textTransform: 'uppercase',
    },
  }),

  { name: 'MembershipForm' },
)

export default useStyles
