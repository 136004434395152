import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import Page from 'src/components/Page/Page'

const EmployerAuthorisationSuccessView: FC = () => {
  const { t } = useTranslation()

  return (
    <Page title={t('Views.ContractConfirmation.title')}>
      <ContentStripe backgroundColor="secondary">
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" align="center" gutterBottom>
              {t('Views.ContractConfirmation.header')}
            </Typography>
          </Grid>
        </Grid>
      </ContentStripe>

      <ContentStripe backgroundColor="default">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" align="center" color={'primary'}>
              {t('Views.ContractConfirmation.description')}
            </Typography>
          </Grid>
        </Grid>
      </ContentStripe>
    </Page>
  )
}

export default EmployerAuthorisationSuccessView
