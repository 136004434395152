export const multipleIds = (allowedValues: number[], cachedValues: string[]) =>
  cachedValues
    ? cachedValues.filter((value) => allowedValues.includes(parseInt(value)))
    : []

export const multipleSelections = (
  allowedValues: string[],
  cachedValues: string[],
) =>
  cachedValues
    ? cachedValues.filter((value) => allowedValues.includes(value))
    : []

export const singleSelection = (allowedValues: string[], cachedValue: string) =>
  cachedValue && allowedValues.includes(cachedValue) ? cachedValue : ''
