import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  }),

  { name: 'HeaderFooterFullWidthLayout' },
)

export default useStyles
