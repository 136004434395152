import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { AppBarProps } from '@material-ui/core'
import {
  AppBar,
  Container,
  Grid,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar,
} from '@material-ui/core'
import { Close as CloseIcon, Menu as MenuIcon } from '@material-ui/icons'
import clsx from 'clsx'

import routes from 'src/routes'
import useUserData from 'src/hooks/useUserDate'
import NavBar from 'src/components/NavBar/NavBar'
import NavDrawer from 'src/components/NavDrawer/NavDrawer'
import type { NavItemProps } from 'src/components/NavItem/NavItem'

import useStyles from './TopBar.styles'

export interface TopBarProps extends AppBarProps {
  className?: string
}

export const TopBar: FC<TopBarProps> = ({ className, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { hasMembershipStatus } = useUserData()

  const navItems: NavItemProps[] = [
    {
      title: t('TopBar.NavItems.Home.title'),
      to: routes.root.path,
    },
    {
      title: t('TopBar.NavItems.Contracts.title'),
      to: routes.root.routes!.contracts.path,
    },
    {
      title: t('TopBar.NavItems.Contact.title'),
      to: routes.root.routes!.contact.path,
    },
  ]
  !hasMembershipStatus &&
    navItems.splice(2, 0, {
      title: t('TopBar.NavItems.Membership.title'),
      to: routes.root.routes!.membership.path,
    })

  return (
    <AppBar elevation={0} className={clsx(classes.root, className)} {...props}>
      <Toolbar
        id="drawer-container"
        style={{ position: 'relative' }}
        className={classes.toolbar}
        disableGutters
      >
        <Container maxWidth="lg">
          <Grid container>
            <Hidden smDown>
              <Grid className={classes.navBarContainer} item>
                <NavBar navItems={navItems} />
              </Grid>
            </Hidden>

            <Hidden mdUp>
              <NavDrawer
                classes={{
                  modal: classes.drawerModal,
                  paper: classes.drawer,
                  paperAnchorTop: classes.drawerAnchor,
                }}
                navItems={navItems}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
              />

              <Grid item style={{ marginLeft: 'auto' }}>
                <IconButton
                  color="default"
                  onClick={() => setDrawerOpen(!drawerOpen)}
                >
                  <SvgIcon fontSize="small">
                    {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                  </SvgIcon>
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
