import { colors, makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    fieldset: {
      width: '100%',
      padding: 0,
      border: 'none',
      margin: 0,
      minWidth: 0, // reset browser default
    },

    accordionSummaryContent: {},

    accordionTitle: {
      marginTop: theme.spacing(0.35),
    },

    checkboxRow: {
      position: 'relative',

      '&:hover': {
        [theme.breakpoints.up('md')]: {
          backgroundColor: theme.palette.action.hover,
        },
      },

      '&:not(:first-child):before': {
        [theme.breakpoints.up('md')]: {
          backgroundColor: theme.palette.divider,
          content: '""',
          width: '100%',
          height: 1,
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
    },

    checkboxRowFormControl: {
      // Mimic horizontal grid container styles to match parent container spacing (2).
      [theme.breakpoints.up('md')]: {
        width: `calc(100% + ${theme.spacing(2)}px)`,
        marginRight: -theme.spacing(1),
        marginLeft: -theme.spacing(1),
        flexDirection: 'row',
      },

      '& > *': {
        // Mimic horizontal grid item styles to match parent container spacing (2).
        [theme.breakpoints.up('md')]: {
          paddingRight: theme.spacing(1),
          paddingLeft: theme.spacing(1),
        },
      },
    },

    checkboxRowLabel: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'center',
      },

      '& .MuiFormControlLabel-root': {
        [theme.breakpoints.up('md')]: {
          margin: 0,
        },
      },
    },
    checkboxError: {
      color: 'red',
    },

    modalCloseButton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },

    dialogRoot: {
      padding: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },

    termAndConditionLink: {
      cursor: 'pointer',
      paddingLeft: theme.spacing(2),
    },

    radioGroup: {
      '& .MuiFormControlLabel-label': {
        // increase spacing between radio buttons
        // (required when label contains multiple lines)
        padding: '0.4rem 0',
      },
    },

    hintIconButton: {
      '&:hover': {
        backgroundColor: colors.blue[100],
      },
    },
  }),

  { name: 'ContractForm' },
)

export default useStyles
