import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { FormikProps } from 'formik'

import { MembershipFormType } from '../MembershipForm'

export interface BonusDataType {
  selectedBonus: string
}

export const initialValues: BonusDataType = {
  selectedBonus: '',
}

export interface BonusDataProps {
  formik: FormikProps<MembershipFormType>
  membershipBonusList: string[]
}

export const BonusData: FC<BonusDataProps> = ({
  formik,
  membershipBonusList,
}) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={2}>
      {membershipBonusList?.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography component="span">
              <Box>{t('MembershipForm.Fields.CheckData.Bonus.label')}</Box>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              error={Boolean(
                formik.touched.selectedBonus && formik.errors.selectedBonus,
              )}
              variant="outlined"
              fullWidth
            >
              <InputLabel id="selected-bonus-label">
                {t('MembershipForm.Fields.CheckData.Bonus.placeholder')}
              </InputLabel>

              <Select
                id="selectedBonus"
                name={'selectedBonus'}
                labelId="selected-bonus-label"
                defaultValue={''}
                error={Boolean(
                  formik.touched.selectedBonus && formik.errors.selectedBonus,
                )}
                label={t('MembershipForm.Fields.CheckData.Bonus.placeholder')}
                value={formik.values.selectedBonus}
                onChange={formik.handleChange}
              >
                {membershipBonusList.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>

              {formik.errors.selectedBonus && (
                <FormHelperText>{formik.errors.selectedBonus}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  )
}
export default BonusData
