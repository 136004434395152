import type { FC } from 'react'
import React, { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {
  ChevronRight as ChevronRightIcon,
  Info as InfoIcon,
} from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import type { FormikProps } from 'formik'
import * as Yup from 'yup'

import type { Contract } from 'src/types'
import { multipleSelections } from 'src/utils/cacheValidation'
import i18n from 'src/services/i18n/i18n'
import Button from 'src/components/Button/Button'
import Markdown from 'src/components/Markdown/Markdown'
import { SurveyComponent } from 'src/components/SurveyComponent'

import { ContractFormType } from '../ContractForm'

import useStyles from '../ContractForm.styles'

export interface PanelCheckDataType {
  isMember: boolean
  hzvNews: boolean
  survey: string
  toggleOtherSurveyOption: boolean
  otherSurveyOption: string
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const initialValues: PanelCheckDataType = {
  isMember: false,
  hzvNews: false,
  survey: '',
  toggleOtherSurveyOption: false,
  otherSurveyOption: '',
}

export const validationSchema = (surveyOptions: string[]) =>
  Yup.object().shape({
    survey: Yup.array()
      .of(Yup.string())
      .transform((current) => multipleSelections(surveyOptions, current)),
    otherSurveyOption: Yup.string().when('toggleOtherSurveyOption', {
      is: true,
      then: Yup.string().max(
        1000,
        i18n.t('General.Form.maxLength', { length: 1000 }),
      ),
    }),
  })
/* eslint-enable */

export interface PanelPanelCheckDataProps {
  className?: string
  formik: FormikProps<ContractFormType>
  regionalAssociationLabel: string | undefined
  regionalAssociationLabelCheckout: string | null | undefined
  selectedContracts: Contract[]
  surveyOptions?: string[]
}

export const PanelPanelCheckData: FC<PanelPanelCheckDataProps> = ({
  className,
  formik,
  regionalAssociationLabel,
  regionalAssociationLabelCheckout,
  selectedContracts,
  surveyOptions = [],
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const termsAndConditions = useRef<null | HTMLDivElement>(null)
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const [openModalWith, setOpenModalWith] = useState<
    { content: JSX.Element; title: string } | boolean
  >(false)

  const affiliatedCompanies: string[] = t(
    'ContractForm.Fields.CheckData.HzvNews.Modal.affiliatedCompanies',
    { returnObjects: true },
  )
  const showSurveyOptions = !!surveyOptions?.length

  return (
    <fieldset className={classes.fieldset} {...props}>
      <Grid container spacing={2}>
        {showSurveyOptions && (
          <SurveyComponent formik={formik} surveyOptions={surveyOptions} />
        )}
        <Grid item xs={12}>
          <FormControl variant="outlined">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.isMember}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="isMember"
                  />
                }
                label={
                  <>
                    <Trans
                      i18nKey={'ContractForm.Fields.CheckData.IsMember.label'}
                      values={{
                        associationLabel: regionalAssociationLabelCheckout
                          ? regionalAssociationLabelCheckout
                          : t(
                              'ContractForm.Fields.CheckData.IsMember.regionalAssociation',
                              {
                                associationLabel: regionalAssociationLabel,
                              },
                            ),
                      }}
                      components={{
                        linkTag: (
                          <Link
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              event.preventDefault() // have to be preventDefault so that the checkbox is not clicked
                              termsAndConditions!.current!.scrollIntoView()
                            }}
                          />
                        ),
                      }}
                    />
                    <Tooltip
                      arrow
                      enterTouchDelay={100}
                      title={
                        t('ContractForm.Fields.CheckData.IsMember.toolTip') ||
                        ''
                      }
                    >
                      <InfoIcon />
                    </Tooltip>
                  </>
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.hzvNews}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="hzvNews"
                  />
                }
                label={
                  <Trans
                    i18nKey={'ContractForm.Fields.CheckData.HzvNews.label'}
                    components={{
                      linkTag: (
                        <Link
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault() // have to be preventDefault so that the checkbox is not clicked
                            setOpenModalWith({
                              content: (
                                <ul style={{ marginLeft: theme.spacing(2) }}>
                                  {affiliatedCompanies.map(
                                    (affiliatedCompany, index) => (
                                      <li key={index}>
                                        <Typography>
                                          {affiliatedCompany}
                                        </Typography>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              ),
                              title: t(
                                'ContractForm.Fields.CheckData.HzvNews.Modal.header',
                              ),
                            })
                          }}
                        />
                      ),
                    }}
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            <strong>
              {t('ContractForm.Fields.CheckData.TermsAndConditions.label')}
            </strong>
          </Typography>
        </Grid>

        <Grid ref={termsAndConditions} item xs={12}>
          <ul style={{ marginLeft: theme.spacing(2) }}>
            {selectedContracts.map((selectedContract) => {
              return selectedContract.generalTermsAndConditions.map(
                (termAndCondition) => (
                  <li key={termAndCondition.id}>
                    <Typography>
                      {termAndCondition.title}
                      <Link
                        className={clsx(classes.termAndConditionLink)}
                        color="textSecondary"
                        onClick={() =>
                          setOpenModalWith({
                            content: (
                              <Markdown>{termAndCondition.text}</Markdown>
                            ),
                            title: selectedContract.abbreviation,
                          })
                        }
                      >
                        {t(
                          'ContractForm.Fields.CheckData.TermsAndConditions.linkText',
                        )}
                      </Link>
                    </Typography>
                  </li>
                ),
              )
            })}
          </ul>
        </Grid>

        <Grid container item justifyContent="flex-end" xs={12}>
          <Grid item>
            <Button
              color="secondary"
              disabled={formik.isSubmitting}
              endIcon={
                formik.isSubmitting ? (
                  <CircularProgress size={20} />
                ) : (
                  <ChevronRightIcon />
                )
              }
              type="submit"
              variant="contained"
            >
              {t('ContractForm.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        classes={{ paper: clsx(classes.dialogRoot) }}
        open={!!openModalWith}
        onClose={() => setOpenModalWith(false)}
        maxWidth="lg"
        fullScreen={!smUp}
        fullWidth
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={12}>
              <IconButton
                onClick={() => setOpenModalWith(false)}
                className={clsx(classes.modalCloseButton)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary" variant="h2">
                {openModalWith !== true &&
                  openModalWith !== false &&
                  openModalWith.title}{' '}
                {t(
                  'ContractForm.Fields.CheckData.HzvNews.Modal.termAndCondition',
                )}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {openModalWith !== true &&
            openModalWith !== false &&
            openModalWith.content}
        </DialogContent>
      </Dialog>
    </fieldset>
  )
}

export default PanelPanelCheckData
