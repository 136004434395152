import { generatePath } from 'react-router-dom'
import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'

import { API_URL } from 'src/constants'
import { getPreviewParams, toSnakeCase } from 'src/utils'

import routes from '../../routes'

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 15,
})

// Add a request interceptor and transform POST request URL-Params to snake_case.
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.params = toSnakeCase(config.params)

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// Add a request interceptor that uses the current location to set the api_key and release_id
// request parameters
axiosInstance.interceptors.request.use(
  (config) => {
    const previewParams = getPreviewParams(window.location)

    if (previewParams && config.method === 'post') {
      const error = new Error(
        'Preview Mode is not allowed to make POST requests.',
      )

      console.error(error)
      throw error
    }

    if (previewParams) {
      config.params = config.params || {}
      config.params['api_key'] = previewParams.apiKey
      config.params['release_id'] = previewParams.releaseId
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(undefined, (error) => {
  const { status } = error.response

  // we only handle this error code as the others are handled by the components in catch blocks.
  if (status === 401 && status === 403 && status === 404) {
    window.location.href = generatePath(routes.errorPage.path, {
      errorCode: status,
    })
  } else {
    return Promise.reject(error)
  }
})

export default axiosInstance
