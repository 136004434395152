import { useContext } from 'react'

import type { UserDataContextValue } from 'src/contexts/UserDataContext'
import UserDataContext from 'src/contexts/UserDataContext'

/**
 * @description Provides the UserDataContext.
 */
const useUserData = (): UserDataContextValue => useContext(UserDataContext)

export default useUserData
