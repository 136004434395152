import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'

import Badge from 'src/components/Badge/Badge'
import Button from 'src/components/Button/Button'

import useStyles from '../ContractForm.styles'

export interface SummaryContentProps {
  className?: string
  expandedPanel: number
  panelNo: number
  completed: boolean
  title: string
}

export const SummaryContent: FC<SummaryContentProps> = ({
  className,
  expandedPanel,
  title,
  panelNo,
  completed,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  })

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Badge
          badgeContent={panelNo}
          color={completed ? 'success' : 'primary'}
          size="small"
          variant="outlined"
        />
      </Grid>

      {expandedPanel > panelNo && smDown && (
        <Grid item>
          <IconButton color="default" size="small">
            <EditIcon />
          </IconButton>
        </Grid>
      )}

      <Grid item xs={12} md>
        <Typography className={classes.accordionTitle}>{title}</Typography>
      </Grid>

      {expandedPanel > panelNo && !smDown && (
        <Grid item>
          <Button
            color="secondary"
            startIcon={<EditIcon />}
            variant="outlined"
            size="small"
          >
            {t('General.edit')}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default SummaryContent
