import type { PreviewParams } from 'src/types'

/**
 * @description Parse release_id and api_key search query parameters from given location.
 *
 * @example getPreviewParams(window.location)
 */
const getPreviewParams = (location: any): PreviewParams | undefined => {
  const params = new URLSearchParams(location.search)
  const releaseId = params.get('release_id')
  const apiKey = params.get('api_key')

  if (releaseId && apiKey) {
    return {
      apiKey: apiKey,
      releaseId: releaseId,
    }
  }

  return undefined
}

export default getPreviewParams
