import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },

    inline: {
      flexDirection: 'row',

      '& $selectInput': {
        marginBottom: 0,
      },

      '& $inputRoot': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },

      '& $selectButton': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        flexGrow: 0,
      },
    },

    inputRoot: {
      backgroundColor: theme.palette.background.paper,
    },

    listbox: {
      // Hide divider when there's nothing to divide.
      '& hr:first-child': {
        display: 'none',
      },
    },

    paper: {
      // Fixes drop-down being just a little too wide.
      width: 'calc(100% - 1.5px)',
      right: -1.5,
      position: 'relative',
    },

    passive: {
      color: theme.palette.text.hint,
    },

    selectInput: {
      marginBottom: theme.spacing(2),
      flexGrow: 1,
    },

    selectButton: {
      // This class is used as reference in combination with the 'inline' styles.
    },
  }),

  { name: 'RegionSelect' },
)

export default useStyles
