import React from 'react'

import useStyles from './styles'

export interface AspectRatioImgInterface {
  src: string
  alt: string
  aspectRatio?: string
}

export const AspectRatioImg: React.FC<AspectRatioImgInterface> = ({
  src,
  alt,
  aspectRatio = '33.33%',
}) => {
  const classes = useStyles({ aspectRatio })

  return (
    <div className={classes.root}>
      <img src={src} className={classes.img} alt={alt} />
    </div>
  )
}
