import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    secondary: {
      '&:hover': {
        // override hover color for secondary button
        backgroundColor: theme.palette.secondary.light,
      },
    },

    tertiary: {
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.tertiary.light,
      },
    },
  }),

  { name: 'Button' },
)

export default useStyles
