import { Qualification } from 'src/types/qualification'

export enum RegionStatus {
  INACTIVE = 1,
  ACTIVE = 2,
  PASSIVE = 3,
}

export enum MembershipDateOption {
  NONE = 1,
  OPTIONAL = 2,
  REQUIRED_ONLY_FUTURE = 3,
  REQUIRED = 4,
}

export interface Membership {
  dateLabel: string
  feeLabel: string
  dateOption: MembershipDateOption
  hasFee: boolean
  hasUpload: boolean
  id: number
  title: string
  uploadLabel: string
}

export interface SpecialContract {
  id: number
  abbreviation: string
  teaser: string
  logoUrl: string
  link1: string
  linkText1: string
}

export interface AdditionalAttribute {
  id: number
  title: string
  informationText: string
  hasUpload: boolean
  isRequired: boolean
}

export interface Region {
  additionalAttributes: AdditionalAttribute[]
  contractStatus: RegionStatus
  contractApplicationRequiresEmployerAuthorisation: boolean
  contractUrl: string | null
  id: number
  membershipStatus: RegionStatus
  membershipUrl: string | null
  membershipInformation: string | null
  name: string
  nameMembership: string
  okv: string
  participatingInsuranceCompanies: string[]
  phonePrimary: string
  phoneSecondary: string
  privacyPolicyUrl: string
  qualifications: Qualification[]
  regionalAssociationLabel: string
  regionalAssociationLogoUrl: string
  regionalAssociationLabelCheckoutHzv: string | null
  regionalAssociationLabelCheckoutMembership: string | null
  regionalMemberships: Membership[]
  showOnContactView: boolean
  slug: string
  statuteUrl: string
  isRegisteredOfficeOwnerOptionActive: boolean
  isEmployedInMvzOptionActive: boolean
  isEmployedInAPracticeOptionActive: boolean
  verahWeeklyWorkTime: string[]
  phoneMembership: string | null
  phoneContract: string | null
  membershipBonus: string[]
  ptqzMembershipActive: boolean
  specialContracts: SpecialContract[]
  contractApplicationSurveyOptions: string[]
  membershipApplicationSurveyOptions: string[]
  hasDmpUpload: boolean
  serviceHoursRow1: string | null
  serviceHoursRow2: string | null
}
