import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    bold: { fontWeight: theme.typography.fontWeightBold as number },
  }),

  { name: 'MembershipFormView' },
)

export default useStyles
