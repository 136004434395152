import type { FC } from 'react'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'

import { TestIds } from 'src/testIds'
import { tArray } from 'src/utils'
import CertificateStripe from 'src/components/CertificateStripe/CertificateStripe'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import Page from 'src/components/Page/Page'

const LegalNoticeTestIds = TestIds.views.legalNotice

const LegalNoticeView: FC = () => {
  const { t } = useTranslation()

  return (
    <Page
      data-test-id={LegalNoticeTestIds.pageWrapper}
      title={t('Views.LegalNotice.title')}
    >
      <ContentStripe backgroundColor="secondary">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1" align="center">
              {t('Views.LegalNotice.title')}
            </Typography>
          </Grid>
        </Grid>
      </ContentStripe>

      <ContentStripe backgroundColor="fog">
        <Card>
          <CardContent>
            <Typography variant="h2" color="primary" gutterBottom>
              {t('Views.LegalNotice.mandatoryInformation.header')}
            </Typography>

            <Typography paragraph>
              {tArray(
                t,
                'Views.LegalNotice.mandatoryInformation.addressLines',
              )?.map((text, index) => (
                <Fragment key={index}>
                  {text}
                  <br />
                </Fragment>
              ))}
            </Typography>

            <Typography paragraph>
              {tArray(
                t,
                'Views.LegalNotice.mandatoryInformation.contactLines',
                {
                  mailToEnd: '</a>',
                  mailToStart: '<a href="mailto:info@hzv.de">',
                },
              )?.map((text, index) => (
                <Fragment key={index}>
                  <span dangerouslySetInnerHTML={{ __html: text }} />
                  <br />
                </Fragment>
              ))}
            </Typography>

            <Typography paragraph>
              {tArray(
                t,
                'Views.LegalNotice.mandatoryInformation.legalLines',
              )?.map((text, index) => (
                <Fragment key={index}>
                  {text}
                  <br />
                </Fragment>
              ))}
            </Typography>

            <Typography variant="h3" color="primary" gutterBottom>
              {t('Views.LegalNotice.legalStatement.header')}
            </Typography>

            <Typography paragraph>
              {tArray(t, 'Views.LegalNotice.legalStatement.paragraphs')?.map(
                (text, index) => (
                  <Fragment key={index}>
                    {text}
                    <br />
                  </Fragment>
                ),
              )}
            </Typography>
          </CardContent>
        </Card>
      </ContentStripe>

      <CertificateStripe />
      <ContactStripe />
    </Page>
  )
}

export default LegalNoticeView
