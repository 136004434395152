import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    cardContent: {
      padding: theme.spacing(4),
    },
    contactName: {
      padding: `${theme.spacing(1)} 0`,
    },
  }),

  { name: 'ContactView' },
)

export default useStyles
