import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollReset = (element?: Element | null): null => {
  const location = useLocation()
  const scrollElement = element ? element : window

  useEffect(() => {
    scrollElement.scrollTo(0, 0)
  }, [location.pathname, scrollElement])

  return null
}

export default useScrollReset
