import { FC } from 'react'
import * as React from 'react'
import { Badge, Card, CardContent, Typography } from '@material-ui/core'

import useStyles from './Step.styles'

export interface StepType {
  step: number
  label: string
  icon: React.ElementType
}
export const Step: FC<StepType> = ({ step, icon: Icon, label }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Badge
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          badgeContent={<div className={classes.badgeContent}>{step}</div>}
          // Prevent warning:
          // Failed prop type: Material-UI: `overlap="rectangle"` was deprecated.
          // Use `overlap="rectangular"` instead.
          overlap="rectangular"
        >
          <Icon fontSize={'large'} color={'primary'} />
        </Badge>
        <Typography color={'primary'} align="center" variant="h5">
          {label}
        </Typography>
      </CardContent>
    </Card>
  )
}
