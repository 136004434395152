import type { FC } from 'react'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'

import { TestIds } from 'src/testIds'
import { tArray } from 'src/utils'
import CertificateStripe from 'src/components/CertificateStripe/CertificateStripe'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import Page from 'src/components/Page/Page'

const GtcTestIds = TestIds.views.gtc

const GtcView: FC = () => {
  const { t } = useTranslation()

  return (
    <Page data-test-id={GtcTestIds.pageWrapper} title={t('Views.Gtc.title')}>
      <ContentStripe backgroundColor="secondary">
        <Grid container>
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h1"
              dangerouslySetInnerHTML={{ __html: t('Views.Gtc.titleFull') }}
            />
          </Grid>
        </Grid>
      </ContentStripe>

      <ContentStripe backgroundColor="fog">
        <Card>
          <CardContent>
            <Typography variant="h2" color="primary" gutterBottom>
              {t('Views.Gtc.header')}
            </Typography>

            {tArray(t, 'Views.Gtc.paragraphs', {
              mailToEnd: '</a>',
              mailToStart: '<a href="mailto:info@hzv.de">',
            })?.map((paragraph, paragraphNo) => (
              <Fragment key={paragraphNo}>
                <Typography variant="h3" color="primary" gutterBottom>
                  § {paragraphNo + 1} {paragraph.title}
                </Typography>

                <Box px={4} mb={2}>
                  <Typography
                    component="ol"
                    style={{ listStyle: paragraph.listStyle }}
                  >
                    {paragraph.subParagraphs.map(
                      (text: string, index: number) => (
                        <Typography
                          gutterBottom
                          component="li"
                          key={index}
                          dangerouslySetInnerHTML={{ __html: text }}
                        />
                      ),
                    )}
                  </Typography>
                </Box>
              </Fragment>
            ))}
          </CardContent>
        </Card>
      </ContentStripe>

      <CertificateStripe />
      <ContactStripe />
    </Page>
  )
}

export default GtcView
