import React from 'react'
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDatePickerProps as MuiKeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'

import { isValidDateString, parsedDate } from 'src/utils/date'

interface KeyboardDatePickerProps
  extends Omit<MuiKeyboardDatePickerProps, 'onChange'> {
  /**
   *
   * @param value: date string in form of 'yyyy-MM-dd'
   */
  onDateChange: (value: string | undefined | null) => void
}
export const KeyboardDatePicker = ({
  onDateChange,
  ...props
}: KeyboardDatePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiKeyboardDatePicker
        autoOk
        format="dd.MM.yyyy"
        fullWidth
        inputVariant="outlined"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        openTo="year"
        variant="inline"
        onChange={(_, value) => {
          const dateValue = parsedDate(value || '')
          value && isValidDateString(value) && dateValue
            ? // our api expects the date to be formatted like yyyy-mm-dd
              // therefore, we transform the string correspondingly,
              // before passing it to the form state (if the date is valid)
              onDateChange(format(dateValue, 'yyyy-MM-dd'))
            : onDateChange(value)
        }}
        placeholder={'TT.MM.JJJJ'}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}
