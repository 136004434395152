export const PDF_FILE_TYPE = 'application/pdf'

export const isPDFValid = (pdfFile: File) => {
  return new Promise((resolve) => {
    if (pdfFile.type !== PDF_FILE_TYPE) {
      resolve(false)
    }
    var reader = new FileReader()
    reader.onload = function webViewerChangeFileReaderOnload(evt) {
      const data = evt.target?.result?.toString().substring(0, 8)
      var regex = new RegExp('%PDF-1.[0-7]')
      resolve(data?.match(regex))
    }
    reader.readAsText(pdfFile)
  })
}
