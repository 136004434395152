import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom'
import { Box, Grid, Typography } from '@material-ui/core'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import useContracts from 'src/hooks/useContracts'
import { AspectRatioImg } from 'src/components/AspectRatioImg'
import Button from 'src/components/Button/Button'
import CertificateStripe from 'src/components/CertificateStripe/CertificateStripe'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import ContractForm from 'src/components/ContractForm/ContractForm'
import { ContractFormStepper } from 'src/components/ContractFormStepper/Stepper'
import Page from 'src/components/Page/Page'

import useRegions from '../../hooks/useRegions'

import useStyles from './ContractFormView.styles'

const ContractFormTestIds = TestIds.views.contractForm

const ContractFormView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams<{ regionSlug: string }>()
  const { getters: regionGetters } = useRegions()
  const {
    state: contractState,
    getters: { getSelectedContractsAbbreviations },
  } = useContracts()
  const selectedContracts = contractState.contractList.selectedContracts
  const selectedContractsAbbreviations = getSelectedContractsAbbreviations()
  const currentRegionSlug = contractState.contractList.currentRegion
  const allRegionContracts = contractState.contracts.filter(
    (contract) => contract.regionSlug === currentRegionSlug,
  )

  const currentRegion = regionGetters.getRegion({
    by: 'slug',
    value: currentRegionSlug ?? '',
  })

  const contractListPath = generatePath(
    routes.root.routes!.contracts.routes!.contractList.path,
    {
      regionSlug: params.regionSlug,
    },
  )

  return (
    <Page
      className={classes.root}
      data-test-id={ContractFormTestIds.pageWrapper}
      title={t('Views.ContractForm.title')}
    >
      {!selectedContracts.length && (
        <ContentStripe backgroundColor="fog">
          <Box display="flex" flexGrow="1" flexDirection="column">
            <Typography align="center" variant="h2" gutterBottom>
              {t('Views.ContractForm.noSelectedContracts')}
            </Typography>

            <Box display="flex" justifyContent="center">
              <Button
                color="secondary"
                component={RouterLink}
                to={contractListPath}
                variant="contained"
              >
                {t('Views.ContractForm.backToContractList')}
              </Button>
            </Box>
          </Box>
        </ContentStripe>
      )}

      {!!selectedContracts.length && (
        <>
          <ContentStripe>
            <Grid
              alignItems="center"
              container
              justifyContent="center"
              spacing={1}
              direction={'column'}
            >
              {currentRegion?.regionalAssociationLogoUrl && (
                <Grid
                  item
                  style={{
                    maxWidth: 350,
                    width: '100%',
                  }}
                >
                  <AspectRatioImg
                    src={currentRegion.regionalAssociationLogoUrl}
                    alt={'Landesverband logo'}
                  />
                </Grid>
              )}
              <Grid item>
                <ContractFormStepper
                  active={'end'}
                  start={{
                    label: t('Views.ContractList.chooseContracts'),
                  }}
                  end={{
                    label: t('Views.ContractForm.applyOnline'),
                    selectedContractsAbbreviations,
                    sublabel: t('Views.ContractForm.numberOfContracts', {
                      all: allRegionContracts?.length,
                      selected: selectedContracts?.length,
                    }),
                  }}
                />
              </Grid>
            </Grid>
          </ContentStripe>

          <ContentStripe backgroundColor="fog">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <ContractForm currentRegion={currentRegion} />
              </Grid>
            </Grid>
          </ContentStripe>
        </>
      )}

      <CertificateStripe />

      <ContactStripe
        regionPhone={currentRegion?.phoneContract}
        serviceHoursRow1={currentRegion?.serviceHoursRow1}
        serviceHoursRow2={currentRegion?.serviceHoursRow2}
      />
    </Page>
  )
}

export default ContractFormView
