import type { FC, ReactNode } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { TestIds } from 'src/testIds'
import type { ContentStripeProps } from 'src/components/ContentStripe/ContentStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import Image from 'src/components/Image/Image'

import useStyles from './CertificateStripe.styles'

export interface CertificateStripeProps
  extends Omit<ContentStripeProps, 'children'> {
  children?: ReactNode
}

export const CertificateStripe: FC<CertificateStripeProps> = ({
  className,
  children,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <ContentStripe
      className={clsx(classes.root, className)}
      data-test-id={TestIds.components.certificateStripe}
      {...props}
    >
      <Grid container justifyContent="center">
        <Grid container item justifyContent="center" md={6}>
          <Box mb={2}>
            <Image src="/static/images/tuev_austria.png" width="115" />
          </Box>

          <Typography align="center" variant="body1">
            {t('CertificateStripe.certificateText')}
          </Typography>
        </Grid>
      </Grid>

      {children}
    </ContentStripe>
  )
}

export default CertificateStripe
