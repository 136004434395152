import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold as number,
    },
    blockItem: {
      margin: '0 auto',
      display: 'block',
    },
  }),

  { name: 'HomeView' },
)

export default useStyles
