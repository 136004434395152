import AxiosMockAdapter from 'axios-mock-adapter'

import axi from './axios'

const axiosMock = new AxiosMockAdapter(axi, {
  delayResponse: 3000,
  onNoMatch: 'passthrough',
})

export default axiosMock
