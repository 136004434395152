import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
} from '@material-ui/core'
import { FormikProps } from 'formik'

import { MembershipFormType } from '../MembershipForm'

import useStyles from '../../ContractForm/ContractForm.styles'

export interface PtqzDataType {
  isPtqzInterested: boolean
  isPtqzModerator: boolean
  selectedBonus: string
}

export const initialValues: PtqzDataType = {
  isPtqzInterested: false,
  isPtqzModerator: false,
  selectedBonus: '',
}

export interface PtqzDataProps {
  formik: FormikProps<MembershipFormType>
}

export const PtqzData: FC<PtqzDataProps> = ({ formik }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            error={Boolean(
              formik.touched.isPtqzInterested && formik.errors.isPtqzInterested,
            )}
            required
            variant="outlined"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.isPtqzInterested}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="isPtqzInterested"
                  />
                }
                label={t(
                  'MembershipForm.Fields.CheckData.Ptqz.isPtqzInterested.label',
                )}
              />
            </FormGroup>
            {formik.touched.isPtqzInterested &&
              formik.errors.isPtqzInterested && (
                <FormHelperText>
                  {formik.errors.isPtqzInterested}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            error={Boolean(
              formik.touched.isPtqzModerator && formik.errors.isPtqzModerator,
            )}
            required
            variant="outlined"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.isPtqzModerator}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="isPtqzModerator"
                  />
                }
                label={t(
                  'MembershipForm.Fields.CheckData.Ptqz.isPtqzModerator.label',
                )}
              />
            </FormGroup>
            {formik.touched.isPtqzModerator &&
              formik.errors.isPtqzModerator && (
                <FormHelperText>{formik.errors.isPtqzModerator}</FormHelperText>
              )}
          </FormControl>
        </Grid>
      </Grid>
    </fieldset>
  )
}
export default PtqzData
