import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  Add as AddIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons'
import type { FormikProps } from 'formik'
import * as Yup from 'yup'

import { customRegExp } from 'src/utils/form.utils'
import i18n from 'src/services/i18n/i18n'
import Button from 'src/components/Button/Button'
import { KeyboardDatePicker } from 'src/components/DatePicker/KeyboardDatePicker'

import { ContractFormType } from '../ContractForm'

import useStyles from '../ContractForm.styles'

export interface PanelOperatingFacilityType {
  bsType1: string
  bsNameOfFacility1: string
  bsId1: string
  bsActiveSince1: any
  bsStreet1: string
  bsStreetNo1: string
  bsCity1: string
  bsPostalCode1: string
  bsPhoneOffice1: string
  bsPhoneMobile1: string
  bsFax1: string
  bsEmail1: string
  bsType2: string
  bsNameOfFacility2: string
  bsId2: string
  bsActiveSince2: any
  bsStreet2: string
  bsStreetNo2: string
  bsCity2: string
  bsPostalCode2: string
  bsPhoneOffice2: string
  bsPhoneMobile2: string
  bsFax2: string
  bsEmail2: string
  bsType3: string
  bsNameOfFacility3: string
  bsId3: string
  bsActiveSince3: any
  bsStreet3: string
  bsStreetNo3: string
  bsCity3: string
  bsPostalCode3: string
  bsPhoneOffice3: string
  bsPhoneMobile3: string
  bsFax3: string
  bsEmail3: string
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const initialValues: PanelOperatingFacilityType = {
  // Main operating Facility
  bsType1: '',
  bsNameOfFacility1: '',
  bsId1: '',
  bsActiveSince1: null, // Must be 'null' or the field is pre-filled with todays date.
  bsStreet1: '',
  bsStreetNo1: '',
  bsCity1: '',
  bsPostalCode1: '',
  bsPhoneOffice1: '',
  bsPhoneMobile1: '',
  bsFax1: '',
  bsEmail1: '',
  // Second operating Facility
  bsType2: '',
  bsNameOfFacility2: '',
  bsId2: '',
  bsActiveSince2: null, // Must be 'null' or the field is pre-filled with todays date.
  bsStreet2: '',
  bsStreetNo2: '',
  bsCity2: '',
  bsPostalCode2: '',
  bsPhoneOffice2: '',
  bsPhoneMobile2: '',
  bsFax2: '',
  bsEmail2: '',
  // Third operating Facility
  bsType3: '',
  bsNameOfFacility3: '',
  bsId3: '',
  bsActiveSince3: null, // Must be 'null' or the field is pre-filled with todays date.
  bsStreet3: '',
  bsStreetNo3: '',
  bsCity3: '',
  bsPostalCode3: '',
  bsPhoneOffice3: '',
  bsPhoneMobile3: '',
  bsFax3: '',
  bsEmail3: '',
}

type PanelOperatingFacilityTypeKey = keyof PanelOperatingFacilityType

export const validationSchema = Yup.object().shape({
  bsType1: Yup.string().required(
    i18n.t('ContractForm.Fields.BsData.BsType.required'),
  ),
  bsNameOfFacility1: Yup.string().max(
    255,
    i18n.t('General.Form.maxLength', { length: 255 }),
  ),
  bsId1: Yup.string()
    .required(i18n.t('ContractForm.Fields.BsData.BsId.required'))
    .min(7, i18n.t('ContractForm.Fields.BsData.BsId.min'))
    .max(9, i18n.t('ContractForm.Fields.BsData.BsId.max'))
    .matches(/^\d+$/, i18n.t('ContractForm.Fields.BsData.BsId.type')),
  bsActiveSince1: Yup.date()
    .typeError(i18n.t('ContractForm.Fields.BsData.BsActiveSince.invalid'))
    .nullable(),
  bsStreet1: Yup.string()
    .required(i18n.t('ContractForm.Fields.BsData.BsStreet.required'))
    .min(2, i18n.t('ContractForm.Fields.BsData.BsStreet.required'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  bsStreetNo1: Yup.string()
    .required(i18n.t('ContractForm.Fields.BsData.BsStreetNo.required'))
    .max(7, i18n.t('ContractForm.Fields.BsData.BsStreetNo.max')),
  bsCity1: Yup.string()
    .required(i18n.t('ContractForm.Fields.BsData.BsCity.required'))
    .min(2, i18n.t('ContractForm.Fields.BsData.BsCity.required'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  bsPostalCode1: Yup.string()
    .required(i18n.t('ContractForm.Fields.BsData.BsPostalCode.required'))
    .matches(
      /^[0-9]{5}$/,
      i18n.t('ContractForm.Fields.BsData.BsPostalCode.match'),
    ),
  bsPhoneOffice1: Yup.string()
    .required(i18n.t('ContractForm.Fields.BsData.BsPhoneOffice.required'))
    .min(7, i18n.t('ContractForm.Fields.BsData.BsPhoneOffice.format'))
    .matches(
      customRegExp.phone,
      i18n.t('ContractForm.Fields.BsData.BsPhoneOffice.format'),
    )
    .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  bsPhoneMobile1: Yup.string()
    .min(7, i18n.t('ContractForm.Fields.BsData.BsPhoneMobile.format'))
    .matches(
      customRegExp.phone,
      i18n.t('ContractForm.Fields.BsData.BsPhoneMobile.format'),
    )
    .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  bsFax1: Yup.string()
    .required(i18n.t('ContractForm.Fields.BsData.BsFax.required'))
    .min(7, i18n.t('ContractForm.Fields.BsData.BsFax.format'))
    .matches(
      customRegExp.phone,
      i18n.t('ContractForm.Fields.BsData.BsFax.format'),
    )
    .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  bsEmail1: Yup.string()
    .matches(
      customRegExp.email,
      i18n.t('ContractForm.Fields.BsData.BsEmail.format'),
    )
    .required(i18n.t('ContractForm.Fields.BsData.BsEmail.required')),

  // Validates BS 2 if bsType2 is set to 1. bsType is set via the "Add form field" button.
  bsActiveSince2: Yup.date()
    .typeError(i18n.t('ContractForm.Fields.BsData.BsActiveSince.invalid'))
    .nullable(),
  bsType2: Yup.string(),
  bsNameOfFacility2: Yup.string().when('bsType2', {
    is: '1',
    then: Yup.string().max(
      255,
      i18n.t('General.Form.maxLength', { length: 255 }),
    ),
  }),
  bsId2: Yup.string().when('bsType2', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsId.required'))
      .min(7, i18n.t('ContractForm.Fields.BsData.BsId.min'))
      .max(9, i18n.t('ContractForm.Fields.BsData.BsId.max'))
      .matches(/^\d+$/, i18n.t('ContractForm.Fields.BsData.BsId.type')),
  }),
  bsStreet2: Yup.string().when('bsType2', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsStreet.required'))
      .min(2, i18n.t('ContractForm.Fields.BsData.BsStreet.required'))
      .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  }),
  bsStreetNo2: Yup.string().when('bsType2', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsStreetNo.required'))
      .max(7, i18n.t('ContractForm.Fields.BsData.BsStreetNo.max')),
  }),
  bsCity2: Yup.string().when('bsType2', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsCity.required'))
      .min(2, i18n.t('ContractForm.Fields.BsData.BsCity.required'))
      .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  }),
  bsPostalCode2: Yup.string().when('bsType2', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsPostalCode.required'))
      .matches(
        /^[0-9]{5}$/,
        i18n.t('ContractForm.Fields.BsData.BsPostalCode.match'),
      ),
  }),
  bsPhoneOffice2: Yup.string().when('bsType2', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsPhoneOffice.required'))
      .min(7, i18n.t('ContractForm.Fields.BsData.BsPhoneOffice.format'))
      .matches(
        customRegExp.phone,
        i18n.t('ContractForm.Fields.BsData.BsPhoneOffice.format'),
      )
      .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  }),
  bsPhoneMobile2: Yup.string().when('bsType2', {
    is: '1',
    then: Yup.string()
      .min(7, i18n.t('ContractForm.Fields.BsData.BsPhoneMobile.format'))
      .matches(
        customRegExp.phone,
        i18n.t('ContractForm.Fields.BsData.BsPhoneMobile.format'),
      )
      .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  }),
  bsFax2: Yup.string().when('bsType2', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsFax.required'))
      .min(7, i18n.t('ContractForm.Fields.BsData.BsFax.format'))
      .matches(
        customRegExp.phone,
        i18n.t('ContractForm.Fields.BsData.BsFax.format'),
      )
      .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  }),
  bsEmail2: Yup.string().when('bsType2', {
    is: '1',
    then: Yup.string()
      .matches(
        customRegExp.email,
        i18n.t('ContractForm.Fields.BsData.BsEmail.format'),
      )
      .required(i18n.t('ContractForm.Fields.BsData.BsEmail.required')),
  }),

  // Validates BS 3 if bsType3 is set to 1. bsType is set via the "Add form field" button.
  bsActiveSince3: Yup.date()
    .typeError(i18n.t('ContractForm.Fields.BsData.BsActiveSince.invalid'))
    .nullable(),
  bsType3: Yup.string(),
  bsNameOfFacility3: Yup.string().when('bsType3', {
    is: '1',
    then: Yup.string().max(
      255,
      i18n.t('General.Form.maxLength', { length: 255 }),
    ),
  }),
  bsId3: Yup.string().when('bsType3', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsId.required'))
      .min(7, i18n.t('ContractForm.Fields.BsData.BsId.min'))
      .max(9, i18n.t('ContractForm.Fields.BsData.BsId.max'))
      .matches(/^\d+$/, i18n.t('ContractForm.Fields.BsData.BsId.type')),
  }),
  bsStreet3: Yup.string().when('bsType3', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsStreet.required'))
      .min(3, i18n.t('ContractForm.Fields.BsData.BsStreet.required'))
      .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  }),
  bsStreetNo3: Yup.string().when('bsType3', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsStreetNo.required'))
      .max(7, i18n.t('ContractForm.Fields.BsData.BsStreetNo.max')),
  }),
  bsCity3: Yup.string().when('bsType3', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsCity.required'))
      .min(3, i18n.t('ContractForm.Fields.BsData.BsCity.required'))
      .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  }),
  bsPostalCode3: Yup.string().when('bsType3', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsPostalCode.required'))
      .matches(
        /^[0-9]{5}$/,
        i18n.t('ContractForm.Fields.BsData.BsPostalCode.match'),
      ),
  }),
  bsPhoneOffice3: Yup.string().when('bsType3', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsPhoneOffice.required'))
      .min(7, i18n.t('ContractForm.Fields.BsData.BsPhoneOffice.format'))
      .matches(
        customRegExp.phone,
        i18n.t('ContractForm.Fields.BsData.BsPhoneOffice.format'),
      )
      .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  }),
  bsPhoneMobile3: Yup.string().when('bsType3', {
    is: '1',
    then: Yup.string()
      .min(7, i18n.t('ContractForm.Fields.BsData.BsPhoneMobile.format'))
      .matches(
        customRegExp.phone,
        i18n.t('ContractForm.Fields.BsData.BsPhoneMobile.format'),
      )
      .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  }),
  bsFax3: Yup.string().when('bsType3', {
    is: '1',
    then: Yup.string()
      .required(i18n.t('ContractForm.Fields.BsData.BsFax.required'))
      .min(7, i18n.t('ContractForm.Fields.BsData.BsFax.format'))
      .matches(
        customRegExp.phone,
        i18n.t('ContractForm.Fields.BsData.BsFax.format'),
      )
      .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  }),
  bsEmail3: Yup.string().when('bsType3', {
    is: '1',
    then: Yup.string()
      .matches(
        customRegExp.email,
        i18n.t('ContractForm.Fields.BsData.BsEmail.format'),
      )
      .required(i18n.t('ContractForm.Fields.BsData.BsEmail.required')),
  }),
})
/* eslint-enable */

export interface PanelOperatingFacilityProps {
  className?: string
  formik: FormikProps<ContractFormType>
}

/**
 * The form fields for the other Operating Facilities are created dynamically.
 * These are currently limited to 2 more. The limitation is done via the button "addOperatingFacility".
 * The button is not displayed as soon as 2 additional fields are displayed.
 * The formik fields and the validation are static!
 * For each additional form field, the validation and the fields must be added statically.
 */
export const PanelOperatingFacility: FC<PanelOperatingFacilityProps> = ({
  className,
  formik,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [additionalOperatingFacility, setAdditionalOperatingFacility] =
    useState<number>(0)

  useEffect(() => {
    // we need to know on initial load if there are additionalOperatingFacilities in initialState (localStorage) so
    // we can display the other fields
    // An alternative to this would be refactoring this to FieldArray
    if (!!formik.values.bsType1 && !!formik.values.bsType2) {
      setAdditionalOperatingFacility(1)
    }
    if (
      !!formik.values.bsType1 &&
      !!formik.values.bsType2 &&
      !!formik.values.bsType3
    ) {
      setAdditionalOperatingFacility(2)
    }
  }, []) // eslint-disable-line

  return (
    <fieldset className={classes.fieldset} {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            error={Boolean(formik.touched.bsType1 && formik.errors.bsType1)}
            required
            variant="outlined"
          >
            <FormLabel>
              {t('ContractForm.Fields.BsData.BsType.label')}
            </FormLabel>

            <RadioGroup
              name="bsType1"
              onChange={formik.handleChange}
              row
              value={formik.values.bsType1}
            >
              <FormControlLabel
                control={<Radio />}
                label={t(
                  'ContractForm.Fields.BsData.BsType.individualPractice',
                )}
                value="1"
              />
              <FormControlLabel
                control={<Radio />}
                label={
                  <Typography
                    component="span"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'ContractForm.Fields.BsData.BsType.professionalPracticeGroup',
                      ),
                    }}
                  />
                }
                value="2"
              />
              <FormControlLabel
                control={<Radio />}
                label={t(
                  'ContractForm.Fields.BsData.BsType.communityOfPractice',
                )}
                value="3"
              />
              <FormControlLabel
                control={<Radio />}
                label={t('ContractForm.Fields.BsData.BsType.mvz')}
                value="4"
              />
            </RadioGroup>

            {formik.touched.bsType1 && formik.errors.bsType1 && (
              <FormHelperText>{formik.errors.bsType1}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Main Operating Facility */}
        <Grid item xs={12}>
          <Typography variant="h4">
            <Box fontWeight="fontWeightBold" mt={2}>
              {t('ContractForm.mainOperatingFacility')}
            </Box>
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            error={Boolean(
              formik.touched.bsNameOfFacility1 &&
                formik.errors.bsNameOfFacility1,
            )}
            fullWidth
            inputProps={{ maxLength: 255 }}
            helperText={
              formik.touched.bsNameOfFacility1 &&
              formik.errors.bsNameOfFacility1
            }
            label={t('ContractForm.Fields.BsData.BsNameOfFacility.label')}
            name="bsNameOfFacility1"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.values.employerName = e.target.value
              formik.handleChange(e)
            }}
            value={formik.values.bsNameOfFacility1}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.bsId1 && formik.errors.bsId1)}
            fullWidth
            inputProps={{ maxLength: 9 }}
            helperText={formik.touched.bsId1 && formik.errors.bsId1}
            label={t('ContractForm.Fields.BsData.BsId.label')}
            name="bsId1"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.bsId1}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <KeyboardDatePicker
            error={Boolean(
              formik.touched.bsActiveSince1 && formik.errors.bsActiveSince1,
            )}
            helperText={
              (formik.touched.bsActiveSince1 && formik.errors.bsActiveSince1) ??
              t('ContractForm.Fields.BsData.BsActiveSince.tooltip')
            }
            label={t('ContractForm.Fields.BsData.BsActiveSince.label')}
            name="bsActiveSince1"
            onBlur={formik.handleBlur}
            onDateChange={(value) =>
              formik.setFieldValue('bsActiveSince1', value)
            }
            value={formik.values.bsActiveSince1}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            error={Boolean(formik.touched.bsStreet1 && formik.errors.bsStreet1)}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={formik.touched.bsStreet1 && formik.errors.bsStreet1}
            label={t('ContractForm.Fields.BsData.BsStreet.label')}
            name="bsStreet1"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.bsStreet1}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            error={Boolean(
              formik.touched.bsStreetNo1 && formik.errors.bsStreetNo1,
            )}
            fullWidth
            inputProps={{ maxLength: 7 }}
            helperText={formik.touched.bsStreetNo1 && formik.errors.bsStreetNo1}
            label={t('ContractForm.Fields.BsData.BsStreetNo.label')}
            name="bsStreetNo1"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.bsStreetNo1}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            error={Boolean(
              formik.touched.bsPostalCode1 && formik.errors.bsPostalCode1,
            )}
            fullWidth
            inputProps={{ maxLength: 5 }}
            helperText={
              formik.touched.bsPostalCode1 && formik.errors.bsPostalCode1
            }
            label={t('ContractForm.Fields.BsData.BsPostalCode.label')}
            name="bsPostalCode1"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.bsPostalCode1}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            error={Boolean(formik.touched.bsCity1 && formik.errors.bsCity1)}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={formik.touched.bsCity1 && formik.errors.bsCity1}
            label={t('ContractForm.Fields.BsData.BsCity.label')}
            name="bsCity1"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.bsCity1}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(
              formik.touched.bsPhoneOffice1 && formik.errors.bsPhoneOffice1,
            )}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={
              formik.touched.bsPhoneOffice1 && formik.errors.bsPhoneOffice1
            }
            label={t('ContractForm.Fields.BsData.BsPhoneOffice.label')}
            name="bsPhoneOffice1"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.bsPhoneOffice1}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(
              formik.touched.bsPhoneMobile1 && formik.errors.bsPhoneMobile1,
            )}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={
              formik.touched.bsPhoneMobile1 && formik.errors.bsPhoneMobile1
            }
            label={t('ContractForm.Fields.BsData.BsPhoneMobile.label')}
            name="bsPhoneMobile1"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsPhoneMobile1}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.bsFax1 && formik.errors.bsFax1)}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={formik.touched.bsFax1 && formik.errors.bsFax1}
            label={t('ContractForm.Fields.BsData.BsFax.label')}
            name="bsFax1"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.bsFax1}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.bsEmail1 && formik.errors.bsEmail1)}
            fullWidth
            helperText={formik.touched.bsEmail1 && formik.errors.bsEmail1}
            label={t('ContractForm.Fields.BsData.BsEmail.label')}
            name="bsEmail1"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.bsEmail1}
            variant="outlined"
          />
        </Grid>

        {additionalOperatingFacility === 0 && (
          <Grid item xs={12} md={6}>
            <Button
              color="caol"
              onClick={() => {
                formik.values.bsType2 = '1'
                setAdditionalOperatingFacility(
                  (numberOfOperatingFacility) => numberOfOperatingFacility + 1,
                )
              }}
              startIcon={<AddIcon />}
              variant="contained"
              data-test-id={'add-additional-operating-facility-button'}
            >
              {t('ContractForm.addOperatingFacility')}
            </Button>
          </Grid>
        )}

        {/* Additional Operating Facility */}
        {[...Array(additionalOperatingFacility)].map((_, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12}>
              <Typography variant="h4">
                <Box fontWeight="fontWeightBold" mt={2}>
                  {t('ContractForm.additionalOperatingFacility')} {index + 2}{' '}
                  {additionalOperatingFacility === index + 1 && (
                    <Button
                      onClick={() => {
                        // Deletes all formik values from the removed form field.
                        // (From the removed Operating Facility)
                        formik.setValues({
                          ...formik.values,
                          [`bsType${index + 2}`]: '',
                          [`bsId${index + 2}`]: '',
                          [`bsActiveSince${index + 2}`]: null,
                          [`bsStreet${index + 2}`]: '',
                          [`bsStreetNo${index + 2}`]: '',
                          [`bsCity${index + 2}`]: '',
                          [`bsPostalCode${index + 2}`]: '',
                          [`bsPhoneOffice${index + 2}`]: '',
                          [`bsPhoneMobile${index + 2}`]: '',
                          [`bsFax${index + 2}`]: '',
                          [`bsEmail${index + 2}`]: '',
                        })
                        setAdditionalOperatingFacility(
                          (numberOfOperatingFacility) =>
                            numberOfOperatingFacility - 1,
                        )
                      }}
                      color="default"
                      variant="contained"
                    >
                      {t('ContractForm.removeOperatingFacility')}
                    </Button>
                  )}
                </Box>
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                error={Boolean(
                  formik.touched[
                    `bsNameOfFacility${
                      index + 2
                    }` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsNameOfFacility${
                        index + 2
                      }` as PanelOperatingFacilityTypeKey
                    ],
                )}
                fullWidth
                inputProps={{ maxLength: 255 }}
                helperText={
                  formik.touched[
                    `bsNameOfFacility${
                      index + 2
                    }` as PanelOperatingFacilityTypeKey
                  ] &&
                  formik.errors[
                    `bsNameOfFacility${
                      index + 2
                    }` as PanelOperatingFacilityTypeKey
                  ]
                }
                label={t('ContractForm.Fields.BsData.BsNameOfFacility.label')}
                name={`bsNameOfFacility${index + 2}`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={
                  formik.values[
                    `bsNameOfFacility${
                      index + 2
                    }` as PanelOperatingFacilityTypeKey
                  ]
                }
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                error={Boolean(
                  formik.touched[
                    `bsId${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsId${index + 2}` as PanelOperatingFacilityTypeKey
                    ],
                )}
                fullWidth
                inputProps={{ maxLength: 9 }}
                helperText={
                  formik.touched[
                    `bsId${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                  formik.errors[
                    `bsId${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                label={t('ContractForm.Fields.BsData.BsId.label')}
                name={`bsId${index + 2}`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={
                  formik.values[
                    `bsId${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <KeyboardDatePicker
                error={Boolean(
                  formik.touched[
                    `bsActiveSince${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsActiveSince${
                        index + 2
                      }` as PanelOperatingFacilityTypeKey
                    ],
                )}
                helperText={
                  (formik.touched[
                    `bsActiveSince${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsActiveSince${
                        index + 2
                      }` as PanelOperatingFacilityTypeKey
                    ]) ??
                  t('ContractForm.Fields.BsData.BsActiveSince.tooltip')
                }
                label={t('ContractForm.Fields.BsData.BsActiveSince.label')}
                name={`bsActiveSince${index + 2}`}
                onBlur={formik.handleBlur}
                onDateChange={(value) =>
                  formik.setFieldValue(`bsActiveSince${index + 2}`, value)
                }
                value={
                  formik.values[
                    `bsActiveSince${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                error={Boolean(
                  formik.touched[
                    `bsStreet${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsStreet${index + 2}` as PanelOperatingFacilityTypeKey
                    ],
                )}
                fullWidth
                inputProps={{ maxLength: 50 }}
                helperText={
                  formik.touched[
                    `bsStreet${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                  formik.errors[
                    `bsStreet${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                label={t('ContractForm.Fields.BsData.BsStreet.label')}
                name={`bsStreet${index + 2}`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={
                  formik.values[
                    `bsStreet${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                error={Boolean(
                  formik.touched[
                    `bsStreetNo${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsStreetNo${index + 2}` as PanelOperatingFacilityTypeKey
                    ],
                )}
                fullWidth
                inputProps={{ maxLength: 7 }}
                helperText={
                  formik.touched[
                    `bsStreetNo${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                  formik.errors[
                    `bsStreetNo${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                label={t('ContractForm.Fields.BsData.BsStreetNo.label')}
                name={`bsStreetNo${index + 2}`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={
                  formik.values[
                    `bsStreetNo${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                error={Boolean(
                  formik.touched[
                    `bsPostalCode${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsPostalCode${
                        index + 2
                      }` as PanelOperatingFacilityTypeKey
                    ],
                )}
                fullWidth
                inputProps={{ maxLength: 5 }}
                helperText={
                  formik.touched[
                    `bsPostalCode${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                  formik.errors[
                    `bsPostalCode${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                label={t('ContractForm.Fields.BsData.BsPostalCode.label')}
                name={`bsPostalCode${index + 2}`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={
                  formik.values[
                    `bsPostalCode${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                error={Boolean(
                  formik.touched[
                    `bsCity${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsCity${index + 2}` as PanelOperatingFacilityTypeKey
                    ],
                )}
                fullWidth
                inputProps={{ maxLength: 50 }}
                helperText={
                  formik.touched[
                    `bsCity${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                  formik.errors[
                    `bsCity${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                label={t('ContractForm.Fields.BsData.BsCity.label')}
                name={`bsCity${index + 2}`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={
                  formik.values[
                    `bsCity${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                error={Boolean(
                  formik.touched[
                    `bsPhoneOffice${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsPhoneOffice${
                        index + 2
                      }` as PanelOperatingFacilityTypeKey
                    ],
                )}
                fullWidth
                inputProps={{ maxLength: 50 }}
                helperText={
                  formik.touched[
                    `bsPhoneOffice${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                  formik.errors[
                    `bsPhoneOffice${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                label={t('ContractForm.Fields.BsData.BsPhoneOffice.label')}
                name={`bsPhoneOffice${index + 2}`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={
                  formik.values[
                    `bsPhoneOffice${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                error={Boolean(
                  formik.touched[
                    `bsPhoneMobile${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsPhoneMobile${
                        index + 2
                      }` as PanelOperatingFacilityTypeKey
                    ],
                )}
                fullWidth
                inputProps={{ maxLength: 50 }}
                helperText={
                  formik.touched[
                    `bsPhoneMobile${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                  formik.errors[
                    `bsPhoneMobile${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                label={t('ContractForm.Fields.BsData.BsPhoneMobile.label')}
                name={`bsPhoneMobile${index + 2}`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={
                  formik.values[
                    `bsPhoneMobile${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                error={Boolean(
                  formik.touched[
                    `bsFax${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsFax${index + 2}` as PanelOperatingFacilityTypeKey
                    ],
                )}
                fullWidth
                inputProps={{ maxLength: 50 }}
                helperText={
                  formik.touched[
                    `bsFax${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                  formik.errors[
                    `bsFax${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                label={t('ContractForm.Fields.BsData.BsFax.label')}
                name={`bsFax${index + 2}`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={
                  formik.values[
                    `bsFax${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                error={Boolean(
                  formik.touched[
                    `bsEmail${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                    formik.errors[
                      `bsEmail${index + 2}` as PanelOperatingFacilityTypeKey
                    ],
                )}
                fullWidth
                helperText={
                  formik.touched[
                    `bsEmail${index + 2}` as PanelOperatingFacilityTypeKey
                  ] &&
                  formik.errors[
                    `bsEmail${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                label={t('ContractForm.Fields.BsData.BsEmail.label')}
                name={`bsEmail${index + 2}`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={
                  formik.values[
                    `bsEmail${index + 2}` as PanelOperatingFacilityTypeKey
                  ]
                }
                variant="outlined"
              />
            </Grid>

            {additionalOperatingFacility < 2 &&
              additionalOperatingFacility === index + 1 && (
                <Grid item xs={12} md={6}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      formik.values[
                        `bsType${index + 3}` as PanelOperatingFacilityTypeKey
                      ] = '1'
                      setAdditionalOperatingFacility(
                        (numberOfOperatingFacility) =>
                          numberOfOperatingFacility + 1,
                      )
                    }}
                    startIcon={<AddIcon />}
                    variant="contained"
                  >
                    {t('ContractForm.addOperatingFacility')}
                  </Button>
                </Grid>
              )}
          </React.Fragment>
        ))}

        <Grid container item justifyContent="flex-end" xs={12}>
          <Grid item>
            <Button
              color="secondary"
              endIcon={<ChevronRightIcon />}
              type="submit"
              variant="contained"
            >
              {t('ContractForm.next')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </fieldset>
  )
}

export default PanelOperatingFacility
