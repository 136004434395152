import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { FormikProps } from 'formik'

import { ContractFormType } from 'src/components/ContractForm/ContractForm'
import { MembershipFormType } from 'src/components/MembershipForm/MembershipForm'

export interface SurveyComponentProps {
  formik: FormikProps<ContractFormType> | FormikProps<MembershipFormType>
  surveyOptions: string[]
}

const styles = {
  mainGrid: {
    marginBottom: '1rem',
  },
  otherStyle: { flexFlow: 'nowrap', flexGrow: 1 },
}

export const SurveyComponent: FC<SurveyComponentProps> = ({
  formik,
  surveyOptions,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <Grid container item md={12} style={styles.mainGrid}>
      <Grid container alignContent="center" item md={6}>
        <Typography variant={'h4'}>
          <Box fontWeight={'fontWeightBold'}>
            {t('ContractForm.Fields.CheckData.Survey.title')}
          </Box>
        </Typography>
      </Grid>
      <Grid container item>
        <FormGroup row>
          {/* Options don't have to be unique, therefore we can't use the option itself as a key */}
          {surveyOptions.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={formik.values.survey.includes(option)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  name="survey"
                  value={option}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      </Grid>
      <FormGroup row style={styles.otherStyle}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.toggleOtherSurveyOption}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="toggleOtherSurveyOption"
              value={formik.values.toggleOtherSurveyOption}
            />
          }
          label={t('ContractForm.Fields.CheckData.Survey.other')}
        />
        <TextField
          error={Boolean(
            formik.touched.otherSurveyOption && formik.errors.otherSurveyOption,
          )}
          fullWidth
          inputProps={{ maxLength: 1000 }}
          helperText={
            formik.touched.otherSurveyOption && formik.errors.otherSurveyOption
          }
          name="otherSurveyOption"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.otherSurveyOption}
          placeholder={t(
            'ContractForm.Fields.CheckData.Survey.otherPlaceholder',
          )}
          variant="outlined"
        />
      </FormGroup>
    </Grid>
  )
}
