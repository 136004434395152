import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      height: '100%',
      minHeight: '100%',
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
    },
  }),

  { name: 'LoadingScreen' },
)

export default useStyles
