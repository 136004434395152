import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    checked: {
      borderColor: theme.palette.accent.main,
      boxShadow: `0 0 3px 2px ${theme.palette.accent.main}`,
    },

    disabled: {
      opacity: 0.5,
    },

    cardHeaderRoot: {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.tertiary.dark,
    },

    cardHeaderAction: {
      margin: 0,
      alignSelf: 'center',
    },

    cardImg: {
      [theme.breakpoints.down('xs')]: {
        maxHeight: 50,
      },
    },

    switchRoot: {
      // the switch has a padding of 12 px (default value of mui).
      // To make the switch vertical on the same line as the text , the text must have a negative margin of 12px.
      marginRight: -12,
    },
  }),
  { name: 'ContractCard' },
)

export default useStyles
